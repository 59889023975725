import styled from "styled-components";
import device from "../breakpoints";

import Colors from "../colors";
import Fonts from "../fonts";

import { BaseContainer, BaseContent } from "../main-container";

import logo from "./img/logo.svg";
import facebookIcon from "./img/facebook.svg";
import twitterIcon from "./img/twitter.svg";
import youtubeIcon from "./img/youtube.svg";
import instagramIcon from "./img/instagram.svg";

// TODO
const SOCIAL_MEDIA = [
  { icon: facebookIcon, alt: "Facebook", link: "" },
  { icon: twitterIcon, alt: "Twitter", link: "" },
  { icon: youtubeIcon, alt: "Youtube", link: "" },
  { icon: instagramIcon, alt: "Instagram", link: "" }
];

const Container = styled(BaseContainer)`
  background: ${Colors.lightGray};
  height: 60px;

  @media ${device.tablet} {
    height: 50px;
  }
`;

const Content = styled(BaseContent)`
  height: 100%;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: middle;
`;

const Icon = styled.img`
  width: 36px;
  height: auto;
  margin: auto 8px;

  @media ${device.tablet} {
    margin: auto 0px;
  }
`;

const SMContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-left: 6px;

  @media ${device.tablet} {
    margin-left: 0px;
  }
`;

const SMTitle = styled.p`
  font-family: ${Fonts.barlow};
  font-weight: 500;
  color: ${Colors.darkGray};
  font-size: 12px;
  line-height: 14.4px;
  margin: auto 0;

  @media ${device.tablet} {
    margin-right: 2px;
    font-size: 14px;
    line-height: 16.8px;
  }
`;

const SMLink = styled.a`
  margin: auto 0 auto 5px;
  display: flex;
  align-items: middle;
  height: 16px;

  @media ${device.tablet} {
    height: 20px;
    margin-left: 8px;
  }
`;

const SMIcon = styled.img`
  height: auto;
  width: 16px;

  @media ${device.tablet} {
    width: 20px;
  }
`;

const Footer = () => {
  return (
    <Container>
      <Content>
        <Icon src={logo} />
        <SMContainer>
          <SMTitle>Encuéntranos en:</SMTitle>
          {SOCIAL_MEDIA.map((sm) => (
            <SMLink key={sm.alt} href={sm.link}>
              <SMIcon src={sm.icon} alt={sm.alt} />
            </SMLink>
          ))}
        </SMContainer>
      </Content>
    </Container>
  );
};

export default Footer;
