import Chart, { ChartWrapperOptions } from "react-google-charts";
import styled from "styled-components";
import Colors from "../../shared/colors";
import Fonts from "../../shared/fonts";
import { ClicksPerCountry } from "./interfaces";

interface WorldMapProps {
  data: ClicksPerCountry[];
}

const UndefineCountry = styled.p`
  font-family: ${Fonts.barlow};
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.darkGray};
  padding-left: 20px;

  b {
    font-weight: 600;
  }
`;

const WorldMap = ({ data }: WorldMapProps) => {
  const clicksWithoutCountry = data
    .filter((country) => country.country_code === null)
    .reduce((previewCount, elem) => {
      return elem.clicks + previewCount;
    }, 0);

  const mapData = data.filter((country) => country.country_code !== null);

  const countriesMatrix =
    mapData.length > 0
      ? mapData.map((country) => {
          const displayName = new Intl.DisplayNames(["es"], {
            type: "region"
          });
          const countryName = displayName.of(country.country_code);
          return [country.country_code, countryName, country.clicks];
        })
      : null;

  if (countriesMatrix) {
    countriesMatrix.unshift(["Código", "País", "Clicks"]);
  }

  const options: ChartWrapperOptions["options"] = {
    animation: {
      startup: true,
      duration: 300,
      easing: "out"
    },
    colorAxis: { colors: [Colors.lightPrimary, Colors.primary] },
    datalessRegionColor: Colors.lightGray,
    fontName: Fonts.barlow,
    tooltip: { textStyle: { color: Colors.darkGray } }
  };

  return (
    <>
      {countriesMatrix && (
        <Chart
          chartType="GeoChart"
          width="100%"
          height="400px"
          data={countriesMatrix}
          options={options}
        />
      )}
      {clicksWithoutCountry !== 0 && (
        <UndefineCountry>
          Ubicación desconocida: <b>{clicksWithoutCountry}</b>
        </UndefineCountry>
      )}
      {!countriesMatrix && "No hay data para mostrar"}
    </>
  );
};

export default WorldMap;
