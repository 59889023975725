const Colors = {
  black: "#000000",
  white: "#ffffff",

  // Grays
  gray: "#636467",
  gray2: "#666666",
  gray3: "#999999",
  lightGray: "#f2f2f2",
  lightGray2: "#E5E5E5",
  lightGray3: "#cccccc",
  lightGray4: "#c4c4c4",
  darkGray: "#191919",
  darkGray2: "#333333",

  // Blues
  primary: "#002EFF",
  lightPrimary: "#D0DBF7",
  darkPrimary: "#093BC8",
  blue: "#2E1FC8",
  darkBlue: "#020247",

  secondary: "#42E8B4",
  darkSecondary: "#0ecc83",
  secondary2: "#FD6C98",

  graph1: "#000072",
  graph2: "#FF3D00",
  graph3: "#2FCBF1",
  graph4: "#00A364",

  error: "#dc2020",
};

export default Colors;
