import styled from "styled-components";
import device from "../../shared/breakpoints";
import Colors from "../../shared/colors";
import Fonts from "../../shared/fonts";
import { TopTenClicks } from "./interfaces";

const Wrapper = styled.div`
  width: 100%;
  overflow-x: scroll;

  @media ${device.tablet} {
    overflow: auto;
  }
`;

const Table = styled.table`
  width: 100%;
  min-width: 500px;
  margin-bottom: 50px;
  border: 1px solid ${Colors.lightGray3};
  border-radius: 10px;
  font-family: ${Fonts.barlow};
  color: ${Colors.darkGray};
  border-spacing: 0;
  text-align: center;
`;

const Head = styled.thead`
  background: ${Colors.lightGray2};
`;

const TH = styled.th`
  padding: 14px;
  color: ${Colors.black};
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  &:first-child {
    border-top-left-radius: 10px;
    width: 92px;
  }

  &:last-child {
    border-top-right-radius: 10px;
    width: 75px;
  }

  &:nth-child(2) {
    width: 150px;
    max-width: 150px;
  }
`;

const TD = styled.td`
  padding: 5px 14px;
  font-size: 14px;
  line-height: 17px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
`;

const TR = styled.tr`
  &:nth-child(even) {
    background-color: ${Colors.lightGray};
  }

  &:last-child {
    border-radius: 0 0 10px 10px;
    ${TD} {
      &:first-child {
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-bottom-right-radius: 10px;
      }
    }
  }

  ${TH}, ${TD} {
    &:nth-child(2),
    &:nth-child(3) {
      text-align: left;
    }

    &:nth-child(2) {
      max-width: 150px;
    }

    &:nth-child(3) {
      max-width: 600px;
    }
  }
`;

interface TopTableProps {
  data: TopTenClicks[];
}

const TopTable = ({ data }: TopTableProps) => {
  return (
    <Wrapper>
      <Table>
        <Head>
          <TR>
            <TH>Ranking</TH>
            <TH>Título</TH>
            <TH>Url</TH>
            <TH>Clicks</TH>
          </TR>
        </Head>
        <tbody>
          {data.map((elem, index) => (
            <TR key={index}>
              <TD>{index + 1}</TD>
              <TD>{elem.title}</TD>
              <TD>{elem.original_link}</TD>
              <TD>{elem.clicks}</TD>
            </TR>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default TopTable;
