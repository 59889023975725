import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { urlPrefix } from "../../../core/environment";
import { useAuth } from "../../../shared/auth/auth-provider";
import device from "../../../shared/breakpoints";
import Colors from "../../../shared/colors";
import { BadCredentialsError, UnknownError } from "../../../shared/errors";
import { BaseContainer, BaseContent } from "../../../shared/main-container";
import FormSection, {
  AddLinkBodyParams,
  BadRequestError,
  ValidationErrorResponse
} from "./form";
import ResponseSection, { ResponseData } from "./response";

const Wrapper = styled(BaseContainer)`
  background: ${Colors.lightGray};
  padding-bottom: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const FormWrapper = styled.div`
  @media ${device.tablet} {
    width: calc(70% - 20px);
  }

  @media ${device.laptop} {
    width: calc(70% - 30px);
  }
`;

const ResponseWrapper = styled.div`
  @media ${device.tablet} {
    width: 30%;
  }
`;

interface ShortenerFormProps {
  onRefreshData: () => void;
  setLoading: (loading: boolean) => void;
  setLoadingMessage: (message: string | undefined) => void;
}

const ShortenerForm = ({
  onRefreshData,
  setLoading,
  setLoadingMessage
}: ShortenerFormProps) => {
  const auth = useAuth();
  const [responseData, setResponseData] = useState<ResponseData | null>(null);

  const onFormSubmit = async (data: AddLinkBodyParams) => {
    try {
      const response = await axios({
        url: `${urlPrefix}/shortLinks`,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${auth.token}`
        },
        data: data
      });

      setResponseData(response.data.data);
      await onRefreshData();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response!.status === 422) {
        const data = error.response!.data as ValidationErrorResponse;

        throw new BadRequestError(data.errors);
      }

      if (axios.isAxiosError(error) && error.response!.status === 401) {
        throw new BadCredentialsError();
      }

      if (axios.isAxiosError(error)) {
        throw new UnknownError();
      }

      throw error;
    }
  };

  return (
    <Wrapper>
      <BaseContent>
        <Container>
          <FormWrapper>
            <FormSection
              onFormSubmit={onFormSubmit}
              setLoading={setLoading}
              setLoadingMessage={setLoadingMessage}
            />
          </FormWrapper>
          <ResponseWrapper>
            <ResponseSection data={responseData} />
          </ResponseWrapper>
        </Container>
      </BaseContent>
    </Wrapper>
  );
};

export default ShortenerForm;
