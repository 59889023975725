import styled from "styled-components";

import Colors from "./colors";
import Fonts from "./fonts";
import device from "./breakpoints";

import arrow from "./icons/arrow.svg";
import arrowDisabled from "./icons/arrow-disabled.svg";

export const Label = styled.label`
  font-family: ${Fonts.barlow};
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${Colors.darkGray2};
  margin-bottom: 8px;
`;

export const FloatingLabel = styled.label`
  color: ${Colors.gray3};
  font-family: ${Fonts.roboto};
  font-weight: 400;
  font-size: 16px;
  line-height: 18.75px;
  padding-left: 2px;
  pointer-events: none;
  text-shadow: none;
  text-transform: capitalize;
  transform-origin: left top;
  transform: scale(1) translate3d(0, 22px, 0);
  transition: 200ms ease all;
`;

const BaseInput = styled.input``;

export const LineInput = styled(BaseInput)`
  display: flex;
  border-radius: 0;
  font-family: ${Fonts.roboto};
  font-size: 100%;
  line-height: 25px;
  text-shadow: none;
  border: 0;
  border-bottom: 1px solid ${Colors.gray};
  color: ${Colors.darkGray};
  flex: 1 1 auto;
  order: 2;
  padding: 4px;
  height: 30px;

  &:placeholder-shown + ${FloatingLabel} {
    cursor: text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }

  &:focus::-webkit-input-placeholder {
    opacity: 1;
  }

  &:not(:placeholder-shown) + ${FloatingLabel} {
    transform: scale(0.8) translate3d(0, 5px, 0);
    color: ${Colors.darkGray};
  }

  &:focus + ${FloatingLabel} {
    transform: scale(0.8) translate3d(0, 5px, 0);
    color: ${Colors.primary};
  }
`;

export const BoxInput = styled(BaseInput)`
  padding: 8px 16px 8px 8px;

  ::-webkit-input-placeholder {
    color: ${Colors.gray2};
  }
`;

export const Select = styled.select`
  padding: 8px 64px 8px 8px;
  appearance: none;
  background-image: url(${arrow});
  background-position: calc(100% - 16px), 50%;
  background-repeat: no-repeat;
  text-overflow: ellipsis;
  cursor: pointer;
  color: ${Colors.darkGray};

  &:disabled {
    background-color: ${Colors.lightGray2};
    border-color: ${Colors.lightGray3};
    background-image: url(${arrowDisabled});
  }
`;

export const ErrorMsg = styled.span`
  position: absolute;
  left: 0;
  bottom: 0;
  color: ${Colors.error};
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 22px;

  ${BoxInput}, ${Select} {
    font-family: ${Fonts.barlow};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${Colors.darkGray};
    border: 1px solid ${Colors.gray2};
    background-color: ${Colors.white};
    border-radius: 4px;
    height: 40px;

    &:hover {
      border-color: ${Colors.darkGray2};
    }
  }

  ${LineInput}, ${BoxInput}, ${Select} {
    &:active,
    &:focus,
    &:focus-visible {
      border-color: ${Colors.primary};
      box-shadow: none;
      outline: none;
    }

    &:disabled {
      background-color: ${Colors.lightGray2};
      border-color: ${Colors.lightGray3};
      cursor: not-allowed;
    }
  }
`;
