import styled from "styled-components";
import device from "../../shared/breakpoints";

export const GraphGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;

export const GraphContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 30px;

  @media ${device.tablet} {
    width: calc((100% - 30px) / 2);
  }
`;

export const GraphTitle = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  width: 100%;
`;

export const PieTitle = styled(GraphTitle)`
  width: 60%;
`;
