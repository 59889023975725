import { AxiosError } from "axios";
import useAxios from "axios-hooks";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import { redirectorPrefix, urlPrefix } from "../../core/environment";
import { useAuth } from "../../shared/auth/auth-provider";
import Colors from "../../shared/colors";
import Fonts from "../../shared/fonts";
import Loader from "../../shared/loader";
import { BaseContainer, BaseContent } from "../../shared/main-container";
import { GraphContainer, GraphGroup, PieTitle } from "./graph-group";
import { LinkData, StatisticsData, TotalClicksData } from "./interfaces";
import PieGraph from "./pie-graph";
import TimeGraphs from "./time-graphs";
import TopTable from "./top-table";
import TotalClicksCards from "./total-clicks-cards";
import WorldMap from "./world-map";

const Wrapper = styled(BaseContainer)`
  padding: 30px 0;
  font-family: ${Fonts.barlow};
  color: ${Colors.black};
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 34px;
  margin-bottom: 50px;
`;

const SubTitle = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 50px;
`;

const LinkDetails = styled.div`
  margin-bottom: 50px;
`;

const Detail = styled.p`
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 5px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
`;

const DetailLabel = styled.span`
  font-weight: 500;
`;

const Statistics = () => {
  const navigate = useNavigate();

  const auth = useAuth();
  const { linkCode, linkId } = useParams();

  const [
    { data: linkData, loading: linkLoading, error: linkError },
    getLinkData
  ] = useAxios<LinkData>(
    {
      url: `${urlPrefix}/shortLinks/${linkId}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth.token}`
      }
    },
    { manual: true }
  );

  const [{ data: statsData, loading: statsLoading, error: statsError }] =
    useAxios<StatisticsData>({
      url: linkCode
        ? `${urlPrefix}/shortLinks/${linkCode}/statistics`
        : `${urlPrefix}/statistics`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth.token}`
      }
    });

  useEffect(() => {
    if (linkId) {
      getLinkData();
    }
  }, [linkId, getLinkData]);

  const onError = (error: AxiosError) => {
    if (error.response!.status === 401) {
      Swal.fire(
        "Tu sesión ha expirado",
        "Inicia sesión nuevamente para poder hacer la solicitud",
        "error"
      );

      navigate("/login", { replace: true });
    } else {
      Swal.fire("¡Ups! Algo salió mal", "Vuelve a intentar", "error");
    }
  };

  if (statsError) {
    onError(statsError);
  }

  if (linkError) {
    onError(linkError);
  }

  const totalClicksData: TotalClicksData = {
    total: statsData?.totalClicks ?? 0,
    lastMonth: statsData?.lastThirtyDaysTotalClicks ?? 0,
    lastWeek: statsData?.lastSevenDaysTotalClicks ?? 0,
    today: statsData?.todayTotalClicks ?? 0
  };

  const vpHistoricalMatrix =
    statsData?.vpClicks?.historical.map((elem) => [
      elem.vp_name,
      elem.clicks
    ]) ?? null;
  if (vpHistoricalMatrix) {
    vpHistoricalMatrix.unshift(["vp", "Clicks"]);
  }

  const vpLastMonthMatrix =
    statsData?.vpClicks?.lastThirtyDays.map((elem) => [
      elem.vp_name,
      elem.clicks
    ]) ?? null;
  if (vpLastMonthMatrix) {
    vpLastMonthMatrix.unshift(["vp", "Clicks"]);
  }

  const devicesMatrix =
    statsData?.devices.map((elem) => [elem.device_type, elem.clicks]) ?? null;
  if (devicesMatrix) {
    devicesMatrix.unshift(["Dispositivo", "Clicks"]);
  }

  const browsersMatrix =
    statsData?.browsers.map((elem) => [elem.browser_name, elem.clicks]) ?? null;
  if (browsersMatrix) {
    browsersMatrix.unshift(["Navegador", "Clicks"]);
  }

  return (
    <>
      {(statsLoading || linkLoading) && <Loader />}
      <Wrapper>
        <BaseContent>
          {statsData && (
            <>
              <Title>
                Estadísticas{" "}
                {linkId && linkData
                  ? `de "${linkData.data.title}"`
                  : "Generales"}
              </Title>
              {linkId && linkData && (
                <LinkDetails>
                  <Detail>
                    <DetailLabel>VP:</DetailLabel>{" "}
                    {linkData.data.area.management.vice_presidency.name}
                  </Detail>
                  <Detail>
                    <DetailLabel>Gerencia:</DetailLabel>{" "}
                    {linkData.data.area.management.name}
                  </Detail>
                  <Detail>
                    <DetailLabel>Área:</DetailLabel> {linkData.data.area.name}
                  </Detail>
                  <Detail>
                    <DetailLabel>Solicitante: </DetailLabel>{" "}
                    {linkData.data.petitioner}
                  </Detail>
                  <Detail>
                    <DetailLabel>Url: </DetailLabel>{" "}
                    {linkData.data.original_link}
                  </Detail>
                  <Detail>
                    <DetailLabel>Url corta: </DetailLabel> {redirectorPrefix}/
                    {linkData.data.code}
                  </Detail>
                </LinkDetails>
              )}
              <TotalClicksCards data={totalClicksData} />
              <SubTitle>Clicks totales por fecha</SubTitle>
              <TimeGraphs data={statsData.dailyClicks} />
              {vpHistoricalMatrix && vpLastMonthMatrix && (
                <>
                  <SubTitle>Enlaces visitados por VP</SubTitle>
                  <GraphGroup>
                    <GraphContainer>
                      <PieGraph data={vpHistoricalMatrix} />
                      <PieTitle>Histórico</PieTitle>
                    </GraphContainer>
                    <GraphContainer>
                      <PieGraph data={vpLastMonthMatrix} />
                      <PieTitle>Últimos 30 días</PieTitle>
                    </GraphContainer>
                  </GraphGroup>
                </>
              )}
              {statsData.topTen && (
                <>
                  <SubTitle>Ranking top 10</SubTitle>
                  <TopTable data={statsData.topTen} />
                </>
              )}
              <GraphGroup>
                {devicesMatrix && (
                  <GraphContainer>
                    <SubTitle>
                      Enlaces visitados por dispositivo (Histórico)
                    </SubTitle>
                    <PieGraph data={devicesMatrix} />
                  </GraphContainer>
                )}
                {browsersMatrix && (
                  <GraphContainer>
                    <SubTitle>
                      Enlaces visitados por navegador (Histórico)
                    </SubTitle>
                    <PieGraph data={browsersMatrix} />
                  </GraphContainer>
                )}
              </GraphGroup>
              <SubTitle>Clicks por países (Histórico)</SubTitle>
              <WorldMap data={statsData.countries} />
            </>
          )}
        </BaseContent>
      </Wrapper>
    </>
  );
};

export default Statistics;
