import { clamp, range } from "lodash";
import styled from "styled-components";
import { useMediaQuery } from "usehooks-ts";
import device from "../../../shared/breakpoints";
import { IconButton } from "../../../shared/buttons";
import Colors from "../../../shared/colors";
import { ShortLinksMeta } from "../interfaces";

import ArrowLeft from "./img/arrow-left.svg";
import ArrowRight from "./img/arrow-right.svg";

interface PaginatorProps {
  meta: ShortLinksMeta;
  onChangeCurrentPage: (page: number) => void;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 75px 20px 40px;

  @media ${device.tablet} {
    padding: 44px 20px 0;
  }
`;

const ArrowBtn = styled(IconButton)`
  width: 14px;
  height: 14px;
  cursor: pointer;

  &:first-child {
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  @media ${device.mobileL} {
    &:first-child {
      margin-right: 16px;
    }

    &:last-child {
      margin-left: 4px;
    }
  }

  @media ${device.tablet} {
    &:first-child {
      margin-right: 22px;
    }

    &:last-child {
      margin-left: 6px;
    }
  }
`;

const PageBtn = styled.button`
  background: none;
  border: none;
  border-radius: 3px;
  padding: 2px;
  min-width: 22px;
  height: 22px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: ${Colors.black};
  cursor: pointer;
  margin-right: 8px;

  &.current {
    background-color: ${Colors.secondary2};
  }

  @media ${device.mobileL} {
    margin-right: 12px;
  }

  @media ${device.tablet} {
    margin-right: 16px;
  }
`;

const Dots = styled.span`
  margin-right: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: ${Colors.black};

  @media ${device.mobileL} {
    margin-right: 12px;
  }

  @media ${device.tablet} {
    margin-right: 16px;
  }
`;

const Paginator = ({ meta, onChangeCurrentPage }: PaginatorProps) => {
  const isDeskStyle = useMediaQuery(device.tablet);

  const RADIUS_SIZE = isDeskStyle ? 5 : 2;

  const startOfRange = clamp(
    meta.current_page - RADIUS_SIZE,
    1,
    meta.last_page
  );
  const endOfRange = clamp(meta.current_page + RADIUS_SIZE, 1, meta.last_page);

  const pageRange = range(startOfRange, endOfRange + 1);

  return (
    <Container>
      <ArrowBtn disabled={meta.current_page === 1}>
        <img
          src={ArrowLeft}
          alt="anterior"
          onClick={() => onChangeCurrentPage(meta.current_page - 1)}
        />
      </ArrowBtn>

      {startOfRange !== 1 && (
        <PageBtn
          className={meta.current_page === 1 ? "current" : ""}
          onClick={() => onChangeCurrentPage(1)}
        >
          1
        </PageBtn>
      )}
      {startOfRange - 1 > 1 && <Dots>...</Dots>}
      {pageRange.map((page) => (
        <PageBtn
          key={page}
          className={meta.current_page === page ? "current" : ""}
          onClick={() => onChangeCurrentPage(page)}
        >
          {page}
        </PageBtn>
      ))}
      {meta.last_page - endOfRange > 1 && <Dots>...</Dots>}
      {endOfRange !== meta.last_page && (
        <PageBtn
          className={meta.current_page === meta.last_page ? "current" : ""}
          onClick={() => onChangeCurrentPage(meta.last_page)}
        >
          {meta.last_page}
        </PageBtn>
      )}
      <ArrowBtn
        disabled={meta.current_page === meta.last_page}
        onClick={() => onChangeCurrentPage(meta.current_page + 1)}
      >
        <img src={ArrowRight} alt="siguiente" />
      </ArrowBtn>
    </Container>
  );
};

export default Paginator;
