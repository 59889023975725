import { ErrorMessage } from "@hookform/error-message";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Swal from "sweetalert2";
import styled from "styled-components";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import validator from "validator";
import { BadRequestError, useAuth } from "../../shared/auth/auth-provider";
import device from "../../shared/breakpoints";
import { OutlineButton } from "../../shared/buttons";
import { BadCredentialsError, UnknownError } from "../../shared/errors";
import Fonts from "../../shared/fonts";
import {
  ErrorMsg,
  FloatingLabel,
  FormGroup,
  LineInput,
} from "../../shared/form-group";
import Loader from "../../shared/loader";
import { BaseContent } from "../../shared/main-container";

import img from "./img/login-img.png";

// Styled components
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: relative;
  padding: 24px 38px 66px;

  @media ${device.laptop} {
    display: grid;
    grid-template-areas:
      "img title"
      "img form"
      "img form";
    column-gap: 34px;
    padding-top: 128px;
  }
`;

const Title = styled.h2`
  font-family: ${Fonts.barlow};
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  font-weight: 400;
  grid-area: title;

  b {
    font-weight: 600;
    display: block;
  }

  @media ${device.laptop} {
    text-align: left;
    font-size: 22px;
  }
`;

const Image = styled.img`
  width: 274px;
  height: auto;
  margin-top: -40px;
  z-index: -1;
  position: relative;
  grid-area: img;

  @media ${device.laptop} {
    width: 470px;
    margin-top: 0;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 350px;
  padding: 10px 0;
  grid-area: form;

  @media ${device.laptop} {
    width: 350px;
    align-items: start;
  }
`;

const FullFG = styled(FormGroup)`
  width: 100%;
  margin-bottom: 10px;
`;

const LoginBtn = styled(OutlineButton)`
  width: 100%;
  margin-top: 20px;

  @media ${device.laptop} {
    width: 170px;
  }
`;

const GeneralError = styled.div`
  position: relative;
  height: 20px;
  width: 100%;
`;
// ----

interface FormInputs {
  email: string;
  password: string;
}

const isObjectWithKey = <T extends string>(
  given: unknown,
  key: T
): given is Partial<Record<T, unknown>> =>
  typeof given === "object" && given !== null && key in given;

const Login = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const auth = useAuth();

  const [loading, setLoading] = useState(false);
  const [backError, setBackError] = useState<string>();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormInputs>();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const from =
    isObjectWithKey(state, "from") &&
    isObjectWithKey(state.from, "pathname") &&
    typeof state.from.pathname === "string"
      ? state.from.pathname
      : "/";

  const onSubmit: SubmitHandler<FormInputs> = async (data: FormInputs) => {
    setLoading(true);

    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    try {
      const token = await executeRecaptcha("yourAction");

      const dataToSend = { ...data, token };

      await auth.signIn(dataToSend);
      navigate(from, { replace: true });
    } catch (error) {
      if (error instanceof BadRequestError) {
        if (error.errors.password) {
          setError("password", {
            type: "custom",
            message: error.errors.password.join(`<br></br>`),
          });
        }
        if (error.errors.email) {
          setError("email", {
            type: "custom",
            message: error.errors.email.join(`<br></br>`),
          });
        }
        if (error.errors.general) {
          setBackError(error.errors.general);
        }
      }

      if (error instanceof BadCredentialsError) {
        Swal.fire(
          "Este usuario no está autorizado o el email y contraseña no coinciden",
          "Revisa si tu email y contraseña están escritos correctamente o consulta si estás autorizado para acceder a este sitio",
          "error"
        );
      }

      if (
        error instanceof UnknownError ||
        !(
          error instanceof BadRequestError ||
          error instanceof BadCredentialsError ||
          error instanceof UnknownError
        )
      ) {
        Swal.fire("¡Ups! Algo salió mal", "Vuelve a intentar", "error");
      }
    }

    setLoading(false);
  };

  return (
    <BaseContent>
      {loading && <Loader />}
      <Wrapper>
        <Container>
          <Title>
            <b>Hola!,</b> Inicia sesión para comenzar
          </Title>

          <Image src={img} />

          <Form onSubmit={handleSubmit(onSubmit)}>
            <FullFG>
              <LineInput
                type="email"
                placeholder="usuario@mail.com"
                {...register("email", {
                  required: "Este campo es obligatorio",
                  validate: (value) =>
                    !validator.isEmail(value)
                      ? "Debe ser un email valido"
                      : undefined,
                })}
              />
              <FloatingLabel>Email</FloatingLabel>
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
              />
            </FullFG>

            <FullFG>
              <LineInput
                type="password"
                placeholder=" "
                {...register("password", {
                  required: "Este campo es obligatorio",
                  minLength: {
                    value: 8,
                    message: "La contraseña debe tener al menos 8 caracteres.",
                  },
                })}
              />
              <FloatingLabel>Contraseña</FloatingLabel>
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
              />
            </FullFG>
            <GeneralError>
              <ErrorMsg className="general">{backError}</ErrorMsg>
            </GeneralError>
            <LoginBtn type="submit">Entrar</LoginBtn>
          </Form>
        </Container>
      </Wrapper>
    </BaseContent>
  );
};

const LoginWithRecaptchaProvider = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcnDzAmAAAAALdKeZqdOXaWKMt7S4kJVKp0r1xW">
      <Login />
    </GoogleReCaptchaProvider>
  );
};

export default LoginWithRecaptchaProvider;
