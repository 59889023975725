import styled from "styled-components";
import Colors from "./colors";
import Fonts from "./fonts";

const BaseButton = styled.button`
  height: 40px;
  padding: 9px 14px;
  border-radius: 25px;
  border: none;
  background: transparent;
  font-family: ${Fonts.barlow};
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  display: flex;
  justify-content: center;
  align-items: middle;
  cursor: pointer;
`;

export const PrimaryButton = styled(BaseButton)`
  background: ${Colors.primary};
  color: ${Colors.white};

  &:hover {
    background: ${Colors.darkPrimary};
  }

  &:disabled {
    background: ${Colors.lightGray2};
    color: ${Colors.gray2};
    cursor: not-allowed;
  }
`;

export const OutlineButton = styled(BaseButton)`
  border: solid 1px ${Colors.primary};
  color: ${Colors.primary};

  &:hover {
    background: ${Colors.lightGray};
  }
`;

export const SecondaryButton = styled(BaseButton)`
  background: ${Colors.secondary};
  color: ${Colors.darkGray};
  font-weight: 400;

  &:hover {
    background: ${Colors.darkSecondary};
  }
`;

export const OutlineWhiteButton = styled(BaseButton)`
  border: solid 1px ${Colors.white};
  color: ${Colors.white};
  font-weight: 400;

  &:hover {
    background: ${Colors.darkGray};
  }
`;

export const IconButton = styled(BaseButton)`
  width: 28px;
  height: 28px;
  padding: 0;
  border-radius: 50%;
`;
