import { ReactNode } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';

import AuthProvider from './shared/auth/auth-provider';
import RequireAuth from './shared/auth/requireAuth';

import { BaseContainer, MainContainer } from './shared/main-container';
import Footer from './shared/footer';
import Header from './shared/header';

import Home from './pages/home';
import Login from './pages/login';
import Components from './pages/components';
import Statistics from './pages/statistics';
import MassCreation from './pages/mass-creation';

interface layoutProps {
  children: ReactNode;
}

const Layout = ({ children }: layoutProps) => {
  return (
    <>
      <Header />
      <MainContainer>
        <BaseContainer>{children}</BaseContainer>
      </MainContainer>
      <Footer />
    </>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Layout>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            {/* <Route
              path="/components"
              element={
                <RequireAuth>
                  <Components />
                </RequireAuth>
              }
            /> */}
            <Route
              path="/estadisticas/:linkCode/:linkId"
              element={
                <RequireAuth>
                  <Statistics />
                </RequireAuth>
              }
            />
            <Route
              path="/estadisticas"
              element={
                <RequireAuth>
                  <Statistics />
                </RequireAuth>
              }
            />
            <Route
              path="/massCreation"
              element={
                <RequireAuth>
                  <MassCreation 
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
          </Routes>
        </Router>
      </Layout>
    </AuthProvider>
  );
};

export default App;
