import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import { useMediaQuery } from "usehooks-ts";
import device from "../../../shared/breakpoints";
import {
  IconButton,
  OutlineWhiteButton,
  PrimaryButton,
  SecondaryButton,
} from "../../../shared/buttons";
import Colors from "../../../shared/colors";
import Fonts from "../../../shared/fonts";
import { OnApplyFilters } from "../interfaces";

import BackArrow from "./img/back-arrow.svg";
import Check from "./img/check-icon.svg";
import DropdownArrow from "./img/dropdown-arrow.svg";
import IconDesk from "./img/modal-filter-icon-desk.svg";
import IconMobile from "./img/modal-filter-icon-mob.svg";

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  z-index: 1000000000;
  background-color: rgba(99, 100, 103, 0.5);

  @media ${device.tablet} {
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.85);
  }
`;

const Form = styled.form`
  width: 320px;
  height: 100vh;
  display: grid;
  grid-template-areas:
    "header"
    "date"
    "clicks"
    "petitioner"
    "footer";
  grid-template-rows: repeat(4, min-content) auto;
  font-family: ${Fonts.barlow};
  color: ${Colors.black};
  background-color: ${Colors.white};
  padding: 45px 20px 70px 68px;
  overflow-y: scroll;

  @media ${device.tablet} {
    overflow-y: visible;
    width: auto;
    height: auto;
    padding: 20px;
    grid-template-areas:
      "header header"
      "date clicks"
      "petitioner clicks"
      "footer footer";
    column-gap: 38px;
    color: ${Colors.white};
    background-color: transparent;
    grid-template-rows: repeat(5, min-content);
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  grid-area: header;
  margin-bottom: 54px;

  @media ${device.tablet} {
    margin-bottom: 26px;
  }
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;

  @media ${device.tablet} {
    width: 18px;
    height: 18px;
    margin-right: 16px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  grid-area: footer;
  align-self: start;
  padding-top: 0;

  ${OutlineWhiteButton}, ${SecondaryButton} {
    min-width: 156px;

    &:last-child {
      margin-left: 30px;
    }
  }

  ${PrimaryButton} {
    min-width: 146px;
    height: 30px;
    font-size: 16px;
    line-height: 19px;
    padding: 5px;

    &:last-child {
      margin-left: 30px;
    }
  }

  @media ${device.tablet} {
    justify-content: flex-end;
    padding-top: 32px;
  }
`;

const FilterTitle = styled.h4`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    margin-bottom: 25px;
  }
`;

const ToggleBtn = styled(IconButton)`
  width: 14px;
  height: 14px;

  img {
    transition: all 0.2s ease-out;
  }

  &.show {
    img {
      transform: rotate(180deg);
    }
  }
`;

const FilterContent = styled.div`
  display: none;
  padding-bottom: 50px;

  &.show {
    display: block;
  }

  @media ${device.tablet} {
    padding-bottom: 0;
  }
`;

const RadioButton = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: ${Colors.white};
  border-radius: 2px;
  border: 1px solid ${Colors.gray3};

  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 3.5px;
    left: 2px;
    width: 14px;
    height: 9px;
    background-image: url(${Check});
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    ${RadioButton} {
      background-color: ${Colors.lightGray};
    }
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked {
    & ~ ${RadioButton} {
      background-color: ${Colors.primary};

      &:after {
        display: block;
      }

      @media ${device.tablet} {
        background-color: ${Colors.darkSecondary};
      }
    }
  }
`;

const FilterContainer = styled.div`
  padding-left: 20px;
  border-bottom: 1px solid ${Colors.lightGray3};
  margin-bottom: 15px;

  @media ${device.tablet} {
    border-bottom: none;
  }
`;

const DateContainer = styled(FilterContainer)`
  grid-area: date;

  @media ${device.tablet} {
    margin-bottom: 46px;
  }
`;
const PetitionerContainer = styled(FilterContainer)`
  grid-area: petitioner;
  border-bottom: none;
`;

const ClicksContainer = styled(FilterContainer)`
  grid-area: clicks;

  @media ${device.tablet} {
    ${FilterTitle} {
      margin-bottom: 15px;
    }
  }
`;

// Options
const DATE_OPTIONS = [
  {
    label: "Fecha de menor a mayor",
    value: 1,
  },
  {
    label: "Fecha de mayor a menor",
    value: 2,
  },
];

const PETITIONER_OPTIONS = [
  {
    label: "Orden ascendente",
    value: 1,
  },
  {
    label: "Orden descendente",
    value: 2,
  },
];

const CLICKS_OPTIONS = [
  {
    label: "De 0 a 100 clicks",
    value: 1,
  },
  {
    label: "De 101 a 1000 clicks",
    value: 2,
  },
  {
    label: "De 1001 a 3000 clicks",
    value: 3,
  },
  {
    label: "De 3001 a 5000 clicks",
    value: 4,
  },
  {
    label: "De 5001 o más clicks",
    value: 5,
  },
];

// Interfaces
interface FilterInputs {
  date?: 1 | 2;
  petitioner?: 1 | 2;
  clicks?: 1 | 2 | 3 | 4 | 5;
}

interface FiltersModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  onApplyFilters: (filtersData: OnApplyFilters) => void;
}

interface SelectedFilters {
  date: number;
  petitioner: number;
  clicks: number;
}

const FiltersModal = ({
  isOpen,
  onCloseModal,
  onApplyFilters,
}: FiltersModalProps) => {
  const isDeskStyle = useMediaQuery(device.tablet);

  const [showDate, setShowDate] = useState<boolean>(true);
  const [showPetitioner, setShowPetitioner] = useState<boolean>(true);
  const [showClicks, setShowClicks] = useState<boolean>(true);

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    date: 0,
    petitioner: 0,
    clicks: 0,
  });

  useEffect(() => {
    if (isDeskStyle) {
      setShowDate(true);
      setShowPetitioner(true);
      setShowClicks(true);
    }
  }, [isDeskStyle]);

  const onToggleDate = () => {
    setShowDate(!showDate);
  };

  const onTogglePetitioner = () => {
    setShowPetitioner(!showPetitioner);
  };

  const onToggleClicks = () => {
    setShowClicks(!showClicks);
  };

  const { register, handleSubmit, setValue } = useForm<FilterInputs>();

  const onSubmit: SubmitHandler<FilterInputs> = async (data: FilterInputs) => {
    const dataToSend = {
      "filters[date_order]": data.date,
      "filters[petitioner_order]": data.petitioner,
      "filters[clicks]": data.clicks,
    };

    onApplyFilters({ filters: dataToSend });
    onCloseModal();
  };

  const onToggleRadioBtn: React.MouseEventHandler<HTMLInputElement> = (e) => {
    const target = e.target as HTMLInputElement;
    const targetKey = target.name as keyof SelectedFilters;
    const targetNumberValue = Number(target.value);

    if (selectedFilters[targetKey] !== targetNumberValue) {
      setSelectedFilters((previous) => ({
        ...previous,
        [targetKey]: targetNumberValue,
      }));
    }

    if (selectedFilters[targetKey] === targetNumberValue) {
      setValue(targetKey, undefined);
      setSelectedFilters((previous) => ({
        ...previous,
        [targetKey]: 0,
      }));
    }
  };

  return createPortal(
    <>
      {isOpen && (
        <Wrapper>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Header>
              {!isDeskStyle && (
                <IconButton onClick={onCloseModal}>
                  <img alt="cerrar" src={BackArrow} />
                </IconButton>
              )}
              <Title>
                <Icon src={isDeskStyle ? IconDesk : IconMobile} />

                {isDeskStyle ? "Filtros" : "Filtrar"}
              </Title>
            </Header>
            <DateContainer>
              <FilterTitle>
                Ordenar
                {!isDeskStyle && (
                  <ToggleBtn
                    type="button"
                    className={showDate ? "show" : ""}
                    onClick={onToggleDate}
                  >
                    <img src={DropdownArrow} alt="desplegar" />
                  </ToggleBtn>
                )}
              </FilterTitle>
              <FilterContent className={showDate ? "show" : ""}>
                {DATE_OPTIONS.map((option) => (
                  <Label key={option.value}>
                    {option.label}
                    <Input
                      type="radio"
                      {...register("date")}
                      value={option.value}
                      onClick={onToggleRadioBtn}
                    />
                    <RadioButton />
                  </Label>
                ))}
              </FilterContent>
            </DateContainer>
            <ClicksContainer>
              <FilterTitle>
                Clicks
                {!isDeskStyle && (
                  <ToggleBtn
                    type="button"
                    className={showClicks ? "show" : ""}
                    onClick={onToggleClicks}
                  >
                    <img src={DropdownArrow} alt="desplegar" />
                  </ToggleBtn>
                )}
              </FilterTitle>
              <FilterContent className={showClicks ? "show" : ""}>
                {CLICKS_OPTIONS.map((option) => (
                  <Label key={option.value}>
                    {option.label}
                    <Input
                      type="radio"
                      {...register("clicks")}
                      value={option.value}
                      onClick={onToggleRadioBtn}
                    />
                    <RadioButton />
                  </Label>
                ))}
              </FilterContent>
            </ClicksContainer>
            <PetitionerContainer>
              <FilterTitle>
                Solicitante
                {!isDeskStyle && (
                  <ToggleBtn
                    type="button"
                    className={showPetitioner ? "show" : ""}
                    onClick={onTogglePetitioner}
                  >
                    <img src={DropdownArrow} alt="desplegar" />
                  </ToggleBtn>
                )}
              </FilterTitle>
              <FilterContent className={showPetitioner ? "show" : ""}>
                {PETITIONER_OPTIONS.map((option) => (
                  <Label key={option.value}>
                    {option.label}
                    <Input
                      type="radio"
                      {...register("petitioner")}
                      value={option.value}
                      onClick={onToggleRadioBtn}
                    />
                    <RadioButton />
                  </Label>
                ))}
              </FilterContent>
            </PetitionerContainer>

            <Footer>
              {isDeskStyle && (
                <>
                  <OutlineWhiteButton onClick={onCloseModal}>
                    Volver
                  </OutlineWhiteButton>
                  <SecondaryButton type="submit">Aplicar</SecondaryButton>
                </>
              )}
              {!isDeskStyle && (
                <PrimaryButton type="submit">Aplicar</PrimaryButton>
              )}
            </Footer>
          </Form>
        </Wrapper>
      )}
    </>,
    document.querySelector("body")!
  );
};

export default FiltersModal;
