import Swal from "sweetalert2";

const copyText = (text: string) => {
  navigator.clipboard.writeText(text);
  Swal.fire({
    title: "Se ha copiado en el portapapeles",
    icon: "success",
    toast: true,
    position: "top-end",
    timer: 3000,
    timerProgressBar: true,
  });
};

export default copyText;
