import styled from "styled-components";
import device from "../../shared/breakpoints";
import Colors from "../../shared/colors";
import Fonts from "../../shared/fonts";
import { TotalClicksData } from "./interfaces";

const MARGIN = 30;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  font-family: ${Fonts.barlow};
  color: ${Colors.black};
`;

const Card = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${Colors.black};
  border-radius: 20px;
  margin-bottom: 30px;
  width: calc((100% - ${MARGIN}px) / 2);

  @media ${device.tablet} {
    width: calc((100% - ${MARGIN * 3}px) / 4);
  }
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 10px;
  text-align: center;

  @media ${device.tablet} {
    font-size: 24px;
    line-height: 26px;
  }
`;

const Value = styled.p`
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
`;

interface TotalClicksCardsProps {
  data: TotalClicksData;
}

const TotalClicksCards = ({ data }: TotalClicksCardsProps) => {
  return (
    <Container>
      <Card>
        <Title>Clicks totales</Title>
        <Value>{data.total}</Value>
      </Card>
      <Card>
        <Title>
          Clicks últimos
          <br /> 30 días
        </Title>
        <Value>{data.lastMonth}</Value>
      </Card>
      <Card>
        <Title>
          Clicks últimos
          <br /> 7 días
        </Title>
        <Value>{data.lastWeek}</Value>
      </Card>
      <Card>
        <Title>Clicks hoy</Title>
        <Value>{data.today}</Value>
      </Card>
    </Container>
  );
};

export default TotalClicksCards;
