import { ErrorMessage } from "@hookform/error-message";
import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import validator from "validator";
import { urlPrefix } from "../../../core/environment";
import { useAuth } from "../../../shared/auth/auth-provider";
import device from "../../../shared/breakpoints";
import { PrimaryButton } from "../../../shared/buttons";
import Colors from "../../../shared/colors";
import { BadCredentialsError, UnknownError } from "../../../shared/errors";
import Fonts from "../../../shared/fonts";
import {
  BoxInput,
  ErrorMsg,
  FormGroup,
  Label,
  Select
} from "../../../shared/form-group";
import { OrganizationData } from "../interfaces";

// Styled Components
const Form = styled.form`
  display: flex;
  flex-direction: column;

  @media ${device.mobileL} {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const FormGroupColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.tablet} {
    width: calc((100% - 30px) / 2);

    &:not(:last-child) {
      margin-right: 30px;
    }
  }
`;

const FlexFG = styled(FormGroup)`
  @media ${device.tablet} {
    max-width: 350px;
  }
`;

const InfoText = styled.span`
  font-family: ${Fonts.barlow};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.gray2};
  padding-top: 8px;
`;

const SubmitBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

const SubmitBtn = styled(PrimaryButton)`
  @media ${device.tablet} {
    width: 160px;
  }
`;
// ----------

// Interfaces

interface OptionType {
  id: number;
  name: string;
}

interface FormInputs {
  vp: string;
  management: string;
  area: string;
  campaign: string;
  petitionerName: string;
  petitionerEmail: string;
  originalUrl: string;
  customUrl: string;
}

export interface AddLinkBodyParams {
  area_id: number;
  campaign: string;
  original_link: string;
  custom_link: string;
  petitioner: string;
  email_petitioner: string;
}

export interface shortenerValidationErrors {
  area_id?: string[];
  campaign?: string[];
  original_link?: string[];
  custom_link?: string[];
  petitioner?: string[];
  email_petitioner?: string[];
}

export interface ValidationErrorResponse {
  errors: shortenerValidationErrors;
  message: string;
}

// ----------

export class BadRequestError extends Error {
  constructor(public errors: shortenerValidationErrors) {
    super();
  }
}

const PlaceholderOption: React.FC<{ text: string }> = ({ text }) => {
  return (
    <option disabled hidden value="">
      {text}
    </option>
  );
};

interface FormSectionProps {
  onFormSubmit: (formValue: AddLinkBodyParams) => void;
  setLoading: (loading: boolean) => void;
  setLoadingMessage: (message: string | undefined) => void;
}

const FormSection = ({
  onFormSubmit,
  setLoading,
  setLoadingMessage
}: FormSectionProps) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [customExample, setCustomExample] = useState("SeguimientoURL");

  const [{ data, loading: dataLoading, error }] = useAxios<OrganizationData>({
    url: `${urlPrefix}/vicePresidencies`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${auth.token}`
    }
  });

  const vpOptions: OptionType[] =
    data?.data.map((elem) => {
      return { id: elem.id, name: elem.name };
    }) ?? [];

  const [managementOptions, setManagementOptions] = useState<OptionType[]>([]);
  const [areaOptions, setAreaOptions] = useState<OptionType[]>([]);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    getValues,
    setValue,
    formState: { errors, isSubmitSuccessful }
  } = useForm<FormInputs>();

  const onChangeVP: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    vpOnChange(event);

    const vpSelected = Number(event.target.value);

    const vp = data?.data.find((elem) => elem.id === vpSelected);
    const managements =
      vp?.managements.map(({ id, name }) => ({ id, name })) ?? [];

    setManagementOptions(managements);
    setValue("management", "");

    setAreaOptions([]);
    setValue("area", "");
  };

  const onChangeManagement: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    managementOnChange(event);

    const vpSelected = Number(getValues("vp"));
    const managementSelected = Number(event.target.value);

    const vp = data?.data.find((elem) => elem.id === vpSelected);
    const management = vp?.managements.find(
      (elem) => elem.id === managementSelected
    );
    const areas = management?.areas.map(({ id, name }) => ({ id, name })) ?? [];

    setAreaOptions(areas);
    setValue("area", "");
  };

  const { onChange: vpOnChange, ...vpProps } = register("vp", {
    validate: (value) =>
      value === "" ? "Este campo es obligatorio." : undefined
  });

  const { onChange: managementOnChange, ...managementProps } = register(
    "management",
    {
      validate: (value) =>
        value === "" ? "Este campo es obligatorio." : undefined
    }
  );

  const onSubmit: SubmitHandler<FormInputs> = async (data: FormInputs) => {
    setLoading(true);
    setLoadingMessage("Espera un momento mientras se acorta la URL...");

    const dataToSend = {
      area_id: Number(data.area),
      campaign: data.campaign,
      original_link: data.originalUrl,
      custom_link: data.customUrl,
      petitioner: data.petitionerName,
      email_petitioner: data.petitionerEmail
    };

    try {
      await onFormSubmit(dataToSend);
      setLoading(false);
      setLoadingMessage(undefined);
      Swal.fire({
        title: "Se ha procesado la solicitud exitosamente!",
        icon: "success",
        toast: true,
        position: "top-end",
        timer: 3000,
        timerProgressBar: true
      });
    } catch (error) {
      setLoading(false);
      setLoadingMessage(undefined);
      if (error instanceof BadRequestError) {
        if (error.errors.area_id) {
          setError("area", {
            type: "custom",
            message: error.errors.area_id.join(`<br></br>`)
          });
        }
        if (error.errors.campaign) {
          setError("campaign", {
            type: "custom",
            message: error.errors.campaign
              .join(`<br></br>`)
              .replaceAll("El campo campaign", "La campaña")
          });
        }
        if (error.errors.original_link) {
          setError("originalUrl", {
            type: "custom",
            message: error.errors.original_link
              .join(`<br></br>`)
              .replaceAll("El valor del campo original link", "El url")
          });
        }
        if (error.errors.custom_link) {
          setError("customUrl", {
            type: "custom",
            message: error.errors.custom_link
              .join(`<br></br>`)
              .replaceAll(
                "El valor del campo custom link",
                "El url personalizado"
              )
          });
        }
        if (error.errors.petitioner) {
          setError("petitionerName", {
            type: "custom",
            message: error.errors.petitioner.join(`<br></br>`)
          });
        }
        if (error.errors.email_petitioner) {
          setError("petitionerEmail", {
            type: "custom",
            message: error.errors.email_petitioner.join(`<br></br>`)
          });
        }
      }

      if (error instanceof BadCredentialsError) {
        Swal.fire(
          "Tu sesión ha expirado",
          "Inicia sesión nuevamente para poder hacer la solicitud",
          "error"
        );

        navigate("/login", { replace: true });
      }

      if (
        error instanceof UnknownError ||
        !(
          error instanceof BadRequestError ||
          error instanceof BadCredentialsError ||
          error instanceof UnknownError
        )
      ) {
        Swal.fire("¡Ups! Algo salió mal", "Vuelve a intentar", "error");
      }
    }

    setLoading(false);
    setLoadingMessage(undefined);
  };

  useEffect(() => {
    setLoading(dataLoading);
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset, dataLoading, setLoading]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroupColumn>
        <FlexFG>
          <Label>VP</Label>
          <Select defaultValue="" {...vpProps} onChange={onChangeVP}>
            <PlaceholderOption text="Selecciona una VP" />
            {vpOptions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </Select>
          <ErrorMessage
            errors={errors}
            name="vp"
            render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
          />
        </FlexFG>
        <FlexFG>
          <Label>Gerencia</Label>
          <Select
            defaultValue=""
            disabled={managementOptions.length === 0}
            {...managementProps}
            onChange={onChangeManagement}
          >
            <PlaceholderOption text="Selecciona una Gerencia" />
            {managementOptions.length !== 0 &&
              managementOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
          </Select>
          <ErrorMessage
            errors={errors}
            name="management"
            render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
          />
        </FlexFG>
        <FlexFG>
          <Label>Área</Label>
          <Select
            defaultValue=""
            disabled={areaOptions.length === 0}
            {...register("area", {
              validate: (value) =>
                value === "" ? "Este campo es obligatorio." : undefined
            })}
          >
            <PlaceholderOption text="Selecciona un Área" />
            {areaOptions.length !== 0 &&
              areaOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
          </Select>
          <ErrorMessage
            errors={errors}
            name="area"
            render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
          />
        </FlexFG>
        <FlexFG>
          <Label>Campaña</Label>
          <BoxInput
            type="text"
            placeholder="Ejemplo campaña"
            {...register("campaign", {
              required: "Este campo es obligatorio.",
              maxLength: {
                value: 45,
                message: "Se permite máximo 45 caracteres."
              }
            })}
          />
          <ErrorMessage
            errors={errors}
            name="campaign"
            render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
          />
        </FlexFG>
      </FormGroupColumn>
      <FormGroupColumn>
        <FlexFG>
          <Label>Solicitante</Label>
          <BoxInput
            type="text"
            placeholder="Juan Pérez"
            {...register("petitionerName", {
              required: "Este campo es obligatorio.",
              maxLength: {
                value: 100,
                message: "Se permite máximo 100 caracteres."
              }
            })}
          />
          <ErrorMessage
            errors={errors}
            name="petitionerName"
            render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
          />
        </FlexFG>
        <FlexFG>
          <Label>Email solicitante</Label>
          <BoxInput
            type="email"
            placeholder="Ej: jperez@mail.com"
            {...register("petitionerEmail", {
              required: "Este campo es obligatorio.",
              maxLength: {
                value: 100,
                message: "Se permite máximo 100 caracteres."
              },
              validate: (value) =>
                !validator.isEmail(value)
                  ? "Debe ser un email valido"
                  : undefined
            })}
          />
          <ErrorMessage
            errors={errors}
            name="petitionerEmail"
            render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
          />
        </FlexFG>
        <FlexFG>
          <Label>URL</Label>
          <BoxInput
            type="text"
            placeholder="Pega la URL original aquí"
            {...register("originalUrl", {
              required: "Este campo es obligatorio.",
              maxLength: {
                value: 255,
                message: "Se permite máximo 255 caracteres."
              },
              validate: (value) =>
                !validator.isURL(value) ? "Debe ser un url valido" : undefined
            })}
          />
          <ErrorMessage
            errors={errors}
            name="originalUrl"
            render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
          />
        </FlexFG>
        <FlexFG>
          <Label>Personaliza tu URL</Label>
          <BoxInput
            type="text"
            placeholder="Ej: SeguimientoURL"
            {...register("customUrl", {
              maxLength: {
                value: 20,
                message: "Se permite máximo 20 caracteres."
              }
            })}
            onChange={(event) => {
              setCustomExample(event.target.value);
            }}
          />
          <ErrorMessage
            errors={errors}
            name="customUrl"
            render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
          />
          <InfoText>
            Tu enlace aparecerá personalizado como https://w2.entel.cl
            {customExample}
          </InfoText>
        </FlexFG>
        <SubmitBtnContainer>
          <SubmitBtn type="submit">Acortar URL</SubmitBtn>
        </SubmitBtnContainer>
      </FormGroupColumn>
    </Form>
  );
};

export default FormSection;
