import { pad, sortBy } from "lodash";
import Chart, { ChartWrapperOptions } from "react-google-charts";
import { useMediaQuery } from "usehooks-ts";
import device from "../../shared/breakpoints";
import Colors from "../../shared/colors";
import Fonts from "../../shared/fonts";

const getRandomColor = () => {
  return `#${pad(Math.floor(Math.random() * 16777215).toString(16), 6, "0")}`;
};

interface PieGraphProps {
  data: (string | number)[][];
}

const PieGraph = ({ data }: PieGraphProps) => {
  const isDeskStyle = useMediaQuery(device.tablet);

  const entelColors = [
    Colors.primary,
    Colors.secondary,
    Colors.secondary2,
    Colors.graph1,
    Colors.graph2,
    Colors.graph3,
  ];

  const colors =
    data.length <= entelColors.length
      ? entelColors
      : [...Array(data.length - entelColors.length)].reduce(
          (previewArray, currentValue) => {
            let newColor = getRandomColor();
            while (entelColors.includes(newColor)) {
              newColor = getRandomColor();
            }

            return [...previewArray, newColor];
          },
          entelColors
        );

  const [dataHeader, ...restOfData] = data;

  const dataSorted = sortBy(restOfData, [(elem) => elem[1]]).reverse();
  dataSorted.unshift(dataHeader);

  const options: ChartWrapperOptions["options"] = {
    animation: {
      startup: true,
      duration: 300,
      easing: "out",
    },
    chartArea: { width: "97%", height: "85%" },
    colors: colors,
    fontName: Fonts.barlow,
    pieResidueSliceLabel: "Otros",
    slices: {
      1: { textStyle: { color: Colors.darkBlue } },
      2: { textStyle: { color: Colors.darkBlue } },
      5: { textStyle: { color: Colors.darkGray } },
    },
    sliceVisibilityThreshold: 0.01,
    tooltip: { textStyle: { color: Colors.darkGray } },
  };

  return (
    <>
      {isDeskStyle && (
        <Chart
          chartType="PieChart"
          data={dataSorted}
          options={options}
          width={"100%"}
          height={"300px"}
        />
      )}
      {!isDeskStyle && (
        <Chart
          chartType="PieChart"
          data={dataSorted}
          options={options}
          width={"100%"}
          height={"200px"}
        />
      )}
    </>
  );
};

export default PieGraph;
