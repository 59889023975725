import styled from "styled-components";
import AuthStatus from "../auth/auth-status";
import device from "../breakpoints";
import Colors from "../colors";
import { BaseContent } from "../main-container";
import Menu from "./menu";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${Colors.lightGray};
  padding: 5px 0 20px;

  @media ${device.tablet} {
    padding: 50px 0 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media ${device.tablet} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const MenuWrapper = styled.div`
  margin-bottom: 36px;

  @media ${device.tablet} {
    width: 30%;
    margin-bottom: 0;
  }
`;

const Navbar = () => {
  return (
    <Wrapper>
      <BaseContent>
        <Container>
          <AuthStatus />
          <MenuWrapper>
            <Menu />
          </MenuWrapper>
        </Container>
      </BaseContent>
    </Wrapper>
  );
};

export default Navbar;
