import { ErrorMessage } from "@hookform/error-message";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import validator from "validator";
import device from "../../../shared/breakpoints";
import { OutlineButton, PrimaryButton } from "../../../shared/buttons";
import Colors from "../../../shared/colors";
import {
  BadCredentialsError,
  NotFoundError,
  UnknownError
} from "../../../shared/errors";
import {
  BoxInput,
  ErrorMsg,
  FormGroup,
  Label
} from "../../../shared/form-group";
import { EditBodyParams, LinkData } from "../interfaces";

const Form = styled.form`
  width: 100%;
  text-align: left;

  @media ${device.tablet} {
    display: flex;
  }
`;

const FormColumn = styled.div`
  width: 100%;

  @media ${device.tablet} {
    &:not(:last-child) {
      margin-right: 33px;
    }

    &:last-child {
      display: flex;
      align-items: center;
    }
  }
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  button {
    width: calc((100% - 20px) / 2);

    &:first-of-type {
      margin-right: 20px;
    }

    @media ${device.laptop} {
      width: 120px;
    }
  }
`;

const FormText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${Colors.black};
  margin-bottom: 18px;
  width: 100%;
`;

interface FormInputs {
  title: string;
  campaign: string;
  petitionerName: string;
  petitionerEmail: string;
  originalUrl: string;
}

export interface EditValidationErrors {
  title: string[];
  campaign?: string[];
  original_link?: string[];
  petitioner?: string[];
  email_petitioner?: string[];
}

export interface EditValidationErrorResponse {
  errors: EditValidationErrors;
  message: string;
}

export class EditBadRequestError extends Error {
  constructor(public errors: EditValidationErrors) {
    super();
  }
}

interface EditFormProps {
  linkData: LinkData;
  onFormSubmit: (linkId: number, formValue: EditBodyParams) => void;
  onCancel: () => void;
  setLoading: (loading: boolean) => void;
}

const EditForm = ({
  linkData,
  onFormSubmit,
  onCancel,
  setLoading
}: EditFormProps) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors }
  } = useForm<FormInputs>({
    defaultValues: {
      title: linkData.title,
      originalUrl: linkData.original_link,
      campaign: linkData.campaign,
      petitionerName: linkData.petitioner,
      petitionerEmail: linkData.email_petitioner
    }
  });

  const onSubmit: SubmitHandler<FormInputs> = async (data: FormInputs) => {
    setLoading(true);

    const dataToSend = {
      title: data.title,
      campaign: data.campaign,
      original_link: data.originalUrl,
      petitioner: data.petitionerName,
      email_petitioner: data.petitionerEmail
    };

    try {
      await onFormSubmit(linkData.id, dataToSend);
      setLoading(false);
      Swal.fire({
        title: "Se ha editado la url exitosamente!",
        icon: "success",
        toast: true,
        position: "top-end",
        timer: 3000,
        timerProgressBar: true
      });
    } catch (error) {
      setLoading(false);
      if (error instanceof EditBadRequestError) {
        if (error.errors.title) {
          setError("title", {
            type: "custom",
            message: error.errors.title
              .join(`<br></br>`)
              .replaceAll("El campo title", "La título")
          });
        }

        if (error.errors.campaign) {
          setError("campaign", {
            type: "custom",
            message: error.errors.campaign
              .join(`<br></br>`)
              .replaceAll("El campo campaign", "La campaña")
          });
        }

        if (error.errors.original_link) {
          setError("originalUrl", {
            type: "custom",
            message: error.errors.original_link
              .join(`<br></br>`)
              .replaceAll("El valor del campo original link", "El url")
          });
        }

        if (error.errors.petitioner) {
          setError("petitionerName", {
            type: "custom",
            message: error.errors.petitioner.join(`<br></br>`)
          });
        }

        if (error.errors.email_petitioner) {
          setError("petitionerEmail", {
            type: "custom",
            message: error.errors.email_petitioner.join(`<br></br>`)
          });
        }
      }

      if (error instanceof BadCredentialsError) {
        Swal.fire(
          "Tu sesión ha expirado",
          "Inicia sesión nuevamente para poder hacer la solicitud",
          "error"
        );

        navigate("/login", { replace: true });
      }

      if (error instanceof NotFoundError) {
        Swal.fire(
          "Esta URL no existe",
          "Recarga la página y vuelve a intentar",
          "error"
        );
      }

      if (
        error instanceof UnknownError ||
        !(
          error instanceof EditBadRequestError ||
          error instanceof BadCredentialsError ||
          error instanceof NotFoundError ||
          error instanceof UnknownError
        )
      ) {
        Swal.fire("¡Ups! Algo salió mal", "Vuelve a intentar", "error");
      }
    }

    setLoading(false);
  };

  const handlerCancel = () => {
    reset();
    onCancel();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormColumn>
        <FormGroup>
          <Label>Título URL</Label>
          <BoxInput
            type="text"
            placeholder="Ejemplo Apple Mundo"
            {...register("title", {
              required: "Este campo es obligatorio.",
              maxLength: {
                value: 200,
                message: "Se permite máximo 200 caracteres."
              }
            })}
          />
          <ErrorMessage
            errors={errors}
            name="title"
            render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
          />
        </FormGroup>
        <FormGroup>
          <Label>URL</Label>
          <BoxInput
            type="text"
            placeholder="Pega la URL original aquí"
            {...register("originalUrl", {
              required: "Este campo es obligatorio.",
              maxLength: {
                value: 255,
                message: "Se permite máximo 255 caracteres."
              },
              validate: (value) =>
                !validator.isURL(value) ? "Debe ser un url valido" : undefined
            })}
          />
        </FormGroup>
        <FormGroup>
          <Label>Campaña</Label>
          <BoxInput
            type="text"
            placeholder="Ejemplo campaña"
            {...register("campaign", {
              required: "Este campo es obligatorio.",
              maxLength: {
                value: 45,
                message: "Se permite máximo 45 caracteres."
              }
            })}
          />
          <ErrorMessage
            errors={errors}
            name="campaign"
            render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
          />
        </FormGroup>
      </FormColumn>
      <FormColumn>
        <FormGroup>
          <Label>Solicitante</Label>
          <BoxInput
            type="text"
            placeholder="Juan Pérez"
            {...register("petitionerName", {
              // required: "Este campo es obligatorio.",
              maxLength: {
                value: 100,
                message: "Se permite máximo 100 caracteres."
              }
            })}
          />
          <ErrorMessage
            errors={errors}
            name="petitionerName"
            render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
          />
        </FormGroup>
        <FormGroup>
          <Label>Email solicitante</Label>
          <BoxInput
            type="email"
            placeholder="Ej: jperez@mail.com"
            {...register("petitionerEmail", {
              required: "Este campo es obligatorio.",
              maxLength: {
                value: 100,
                message: "Se permite máximo 100 caracteres."
              },
              validate: (value) =>
                !validator.isEmail(value)
                  ? "Debe ser un email valido"
                  : undefined
            })}
          />
          <ErrorMessage
            errors={errors}
            name="petitionerEmail"
            render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
          />
        </FormGroup>
      </FormColumn>
      <FormColumn>
        <ButtonsContainer>
          <FormText>¿Deseas guardar los cambios realizados?</FormText>
          <OutlineButton type="button" onClick={handlerCancel}>
            Cancelar
          </OutlineButton>
          <PrimaryButton type="submit">Guardar</PrimaryButton>
        </ButtonsContainer>
      </FormColumn>
    </Form>
  );
};

export default EditForm;
