import axios from "axios";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import { useMediaQuery } from "usehooks-ts";
import { urlPrefix } from "../../../core/environment";
import { useAuth } from "../../../shared/auth/auth-provider";
import device from "../../../shared/breakpoints";
import {
  BadCredentialsError,
  NotFoundError,
  UnknownError
} from "../../../shared/errors";
import { ActionFunctions, EditBodyParams, LinkData } from "../interfaces";
import CardMobile from "./card-mobile";
import { EditBadRequestError, EditValidationErrorResponse } from "./edit-form";
import TableDesk from "./table-desk";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ListProps {
  linksData: LinkData[];
  onRefreshData: () => void;
  setLoading: (loading: boolean) => void;
}

const List = ({ linksData, onRefreshData, setLoading }: ListProps) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const isDeskStyle = useMediaQuery(device.tablet);

  const onEdit = async (linkId: number, data: EditBodyParams) => {
    try {
      const response = await axios({
        url: `${urlPrefix}/shortLinks/${linkId}`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${auth.token}`
        },
        data: data
      });

      console.log(response);

      await onRefreshData();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response!.status === 422) {
        const data = error.response!.data as EditValidationErrorResponse;

        throw new EditBadRequestError(data.errors);
      }

      if (axios.isAxiosError(error) && error.response!.status === 401) {
        throw new BadCredentialsError();
      }

      if (axios.isAxiosError(error) && error.response!.status === 404) {
        throw new NotFoundError();
      }

      if (axios.isAxiosError(error)) {
        throw new UnknownError();
      }

      throw error;
    }
  };

  const onDelete = async (linkId: number) => {
    try {
      const swalResponse = await Swal.fire({
        title: "Estás seguro de que quieres eliminar este URL",
        icon: "warning",
        confirmButtonText: "Eliminar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "delete"
        }
      });

      if (swalResponse.isConfirmed) {
        const response = await axios({
          url: `${urlPrefix}/shortLinks/${linkId}`,
          method: "DELETE",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${auth.token}`
          }
        });

        console.log(response);

        onRefreshData();
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response!.status === 401) {
        throw new BadCredentialsError();
      }

      if (axios.isAxiosError(error) && error.response!.status === 404) {
        throw new NotFoundError();
      }

      if (axios.isAxiosError(error)) {
        throw new UnknownError();
      }

      throw error;
    }
  };

  const onStats = (linkCode: string, linkId: number) => {
    navigate(`/estadisticas/${linkCode}/${linkId}`);
  };

  const actionFunctions: ActionFunctions = {
    onEdit,
    onDelete,
    onStats
  };

  return (
    <Container>
      {isDeskStyle && (
        <TableDesk
          linksData={linksData}
          actionFunctions={actionFunctions}
          setLoading={setLoading}
        />
      )}
      {!isDeskStyle && (
        <>
          {linksData.map((item) => (
            <CardMobile
              key={item.id}
              linkData={item}
              actionFunctions={actionFunctions}
              setLoading={setLoading}
            />
          ))}
        </>
      )}
    </Container>
  );
};

export default List;
