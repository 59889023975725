import { useState } from "react";
import styled from "styled-components";
import formatDate from "../../../core/functions/format-date";
import device from "../../../shared/breakpoints";
import Colors from "../../../shared/colors";
import Fonts from "../../../shared/fonts";
import { ActionFunctions, ClickActionFunctions, LinkData } from "../interfaces";
import ActionButtons from "./action-buttons";
import Details from "./details";
import EditForm from "./edit-form";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 12% 32% 14% 14% 10% 18%;
  align-items: stretch;
  justify-items: stretch;
  border: 1px solid ${Colors.lightGray3};
  border-radius: 10px;

  @media ${device.laptop} {
    grid-template-columns: 12% 36% 14% 14% 10% 14%;
  }
`;

const GridItem = styled.div`
  font-family: ${Fonts.barlow};
  color: ${Colors.darkGray};
  text-align: center;
  padding: 14px 0 14px 14px;

  &:nth-child(n + 13) {
    border-top: 1px solid ${Colors.lightGray};
  }

  &:nth-child(7n + 12) {
    padding-right: 14px;
  }

  &:nth-child(7n + 8) {
    text-align: left;
  }
`;

const GridHeaderItem = styled(GridItem)`
  background: ${Colors.lightGray};
  color: ${Colors.black};
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  border-bottom: none;

  &:first-of-type {
    border-top-left-radius: 10px;
  }

  &:nth-of-type(2) {
    text-align: left;
  }

  &:nth-of-type(6) {
    border-top-right-radius: 10px;
    padding-right: 14px;
  }
`;

const GridItemFull = styled(GridItem)`
  padding: 25px;
  grid-column-start: 1;
  grid-column-end: 7;
  border-top: none !important;
  display: none;

  &.open {
    &.edit {
      display: block;
    }

    &:not(.edit) {
      display: grid;
      grid-template-columns: repeat(3, calc((100% - 60px) / 3));
      grid-column-gap: 30px;
    }
  }

  @media ${device.laptop} {
    padding: 25px 50px;

    &.edit {
      padding-bottom: 5px;
    }
  }
`;

const GridItemText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

const GridItemTitle = styled(GridItemText)`
  font-size: 16px;
  line-height: 19px;
`;

const GridItemSubText = styled(GridItemText)`
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.gray};
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
`;

interface TableRowProps {
  linkData: LinkData;
  actionFunctions: ActionFunctions;
  setLoading: (loading: boolean) => void;
}

const TableRow = ({ linkData, actionFunctions, setLoading }: TableRowProps) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [showing, setShowing] = useState<boolean>(false);

  const onClickShow = () => {
    setShowing(!showing);
    setEditing(false);
  };

  const onClickEdit = () => {
    setEditing(!editing);
    setShowing(false);
  };

  const actions: ClickActionFunctions = {
    onClickShow,
    onClickEdit,
    onClickDelete: actionFunctions.onDelete,
    onClickStats: actionFunctions.onStats
  };

  return (
    <>
      <GridItem>
        <GridItemText>{linkData.code}</GridItemText>
      </GridItem>
      <GridItem>
        <GridItemTitle>{linkData.title}</GridItemTitle>
        <GridItemSubText>{linkData.original_link}</GridItemSubText>
      </GridItem>
      <GridItem>
        <GridItemText>{formatDate(linkData.created_at)}</GridItemText>
      </GridItem>
      <GridItem>
        <GridItemText>{linkData.petitioner}</GridItemText>
      </GridItem>
      <GridItem>
        <GridItemText>{linkData.statistics_count ?? "0"}</GridItemText>
      </GridItem>
      <GridItem>
        <ActionButtons
          linkId={linkData.id}
          linkCode={linkData.code}
          actions={actions}
          editState={editing}
        />
      </GridItem>
      <GridItemFull
        className={editing || showing ? `open ${editing ? "edit" : ""}` : ""}
      >
        {showing && <Details data={linkData} />}
        {editing && (
          <EditForm
            linkData={linkData}
            onFormSubmit={actionFunctions.onEdit}
            onCancel={() => setEditing(false)}
            setLoading={setLoading}
          />
        )}
      </GridItemFull>
    </>
  );
};

interface TableDeskProps {
  linksData: LinkData[];
  actionFunctions: ActionFunctions;
  setLoading: (loading: boolean) => void;
}

const TableDesk = ({
  linksData,
  actionFunctions,
  setLoading
}: TableDeskProps) => {
  return (
    <GridContainer>
      <GridHeaderItem>Short Url</GridHeaderItem>
      <GridHeaderItem>Original URL</GridHeaderItem>
      <GridHeaderItem>Fecha</GridHeaderItem>
      <GridHeaderItem>Solicitante</GridHeaderItem>
      <GridHeaderItem>Clicks</GridHeaderItem>
      <GridHeaderItem>Acciones</GridHeaderItem>
      {linksData && (
        <>
          {linksData.map((item) => (
            <TableRow
              key={item.id}
              linkData={item}
              actionFunctions={actionFunctions}
              setLoading={setLoading}
            />
          ))}
        </>
      )}
    </GridContainer>
  );
};

export default TableDesk;
