import { createPortal } from "react-dom";
import styled, { keyframes } from "styled-components";
import device from "../breakpoints";
import Colors from "../colors";
import Fonts from "../fonts";

import logo from "./img/entel-icon.png";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 10000000000;
  backdrop-filter: blur(15px);
`;

const Container = styled.div`
  position: relative;
`;

const Spinners = styled.div`
  display: block;
  position: relative;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid ${Colors.primary};
  animation: ${spin} 2s linear infinite;

  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 4px solid transparent;
  }

  &::before {
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    border-top-color: ${Colors.secondary};
    animation: ${spin} 3.5s linear infinite;
  }
  &::after {
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    border-top-color: ${Colors.secondary2};
    animation: ${spin} 1.75s linear infinite;
  }
`;

const BoxImg = styled.div`
  position: absolute;
  top: 18px;
  bottom: 18px;
  left: 18px;
  right: 18px;
  background-color: ${Colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 4px solid ${Colors.white};
`;

const Message = styled.p`
  font-family: ${Fonts.barlow};
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: ${Colors.black};
  text-align: center;
  padding: 0 30px;

  @media ${device.tablet} {
    font-size: 26px;
    line-height: 32px;
  }
`;

interface LoaderProps {
  message?: string;
}

const Loader = ({ message }: LoaderProps) => {
  return createPortal(
    <Wrapper>
      <Container>
        <Spinners />
        <BoxImg>
          <img alt="logo" src={logo} />
        </BoxImg>
      </Container>
      {message && <Message>{message}</Message>}
    </Wrapper>,
    document.querySelector("body")!
  );
};

export default Loader;
