import styled from "styled-components";
import device from "../breakpoints";

import Colors from "../colors";

import { BaseContainer, BaseContent } from "../main-container";

import logo from "./img/logo.svg";

const Container = styled(BaseContainer)`
  height: 65px;
  border-bottom: solid 1px ${Colors.lightGray3};
  background: ${Colors.white};
`;

const Content = styled(BaseContent)`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: middle;
`;

const Icon = styled.img`
  width: 41px;
  height: auto;
  margin: auto 8px;

  @media ${device.tablet} {
    margin: auto 0px;
  }
`;

const Header = () => {
  return (
    <Container>
      <Content>
        <Icon src={logo} />
      </Content>
    </Container>
  );
};

export default Header;
