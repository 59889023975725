import styled from "styled-components";
import { redirectorPrefix } from "../../../core/environment";
import copyText from "../../../core/functions/copy-text";
import device from "../../../shared/breakpoints";
import Colors from "../../../shared/colors";
import Fonts from "../../../shared/fonts";
import copyIcon from "./img/copy-icon.svg";
import responseImg from "./img/response-img.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 38px;

  &.withoutData {
    align-items: center;
  }

  @media ${device.tablet} {
    padding-top: 20px;
  }
`;

const ResponseImage = styled.img`
  width: 208px;
  height: auto;
`;

const ResponseText = styled.p`
  font-family: ${Fonts.barlow};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${Colors.black};
  max-width: 238px;
`;

const InfoGroup = styled.div`
  font-family: ${Fonts.barlow};
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
`;

const InfoLabel = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${Colors.black};
  margin-bottom: 8px;

  @media ${device.tablet} {
    font-size: 20px;
  }
`;

const InfoText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.darkGray};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const CopyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray3};
  padding-bottom: 10px;
`;

const InfoTextCopy = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: ${Colors.primary};
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 44px);
`;

const CopyBtn = styled.button`
  border: none;
  background-color: transparent;
  background-image: url(${copyIcon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin-left: 20px;
  cursor: pointer;
`;

export interface ResponseData {
  id: number;
  user_id: number;
  area_id: number;
  title: string;
  campaign: string;
  original_link: string;
  code: string;
  is_custom_code: boolean;
  petitioner: string;
  email_petitioner: string;
  created_at: string; // ISO string
  updated_at: string; // ISO string
  deleted_at: string | null; // ISO string
}

const ResponseSection = ({ data }: { data: ResponseData | null }) => {
  return (
    <Container className={!data ? "withoutData" : ""}>
      {!data && (
        <>
          <ResponseImage src={responseImg} />
          <ResponseText>Acá se mostrará la URL acortada.</ResponseText>
        </>
      )}

      {data && (
        <>
          <InfoGroup>
            <InfoLabel>Tu link reducido</InfoLabel>
            <CopyContainer>
              <InfoTextCopy>
                {redirectorPrefix}/{data?.code}
              </InfoTextCopy>
              <CopyBtn
                onClick={() => copyText(`${redirectorPrefix}/${data?.code}`)}
              />
            </CopyContainer>
          </InfoGroup>
          <InfoGroup>
            <InfoLabel>Link extendido</InfoLabel>
            <InfoText>{data.original_link}</InfoText>
          </InfoGroup>
          <InfoGroup>
            <InfoLabel>Título</InfoLabel>
            <InfoText>{data.title}</InfoText>
          </InfoGroup>
        </>
      )}
    </Container>
  );
};

export default ResponseSection;
