import styled from "styled-components";
import device from "../../../shared/breakpoints";
import Colors from "../../../shared/colors";
import Fonts from "../../../shared/fonts";
import { BaseContainer, BaseContent } from "../../../shared/main-container";
import { OnApplyFilters, ShortLinksApi } from "../interfaces";
import Filters from "./filters";
import List from "./list";
import Paginator from "./paginator";

const Wrapper = styled(BaseContainer)`
  padding: 30px 0;
`;

const Title = styled.h1`
  font-family: ${Fonts.barlow};
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  color: ${Colors.black};
  margin-bottom: 10px;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 31px;

  @media ${device.tablet} {
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 17px;
  }
`;

const NoDataText = styled.p`
  text-align: center;
  padding: 30px;
`;

interface ListUrlsProps {
  linksData: ShortLinksApi;
  onFiltersChange: (filtersData: OnApplyFilters) => void;
  onChangeCurrentPage: (page: number) => void;
  onRefreshData: () => void;
  setLoading: (loading: boolean) => void;
}

const ListUrls = ({
  linksData,
  onFiltersChange,
  onChangeCurrentPage,
  onRefreshData,
  setLoading
}: ListUrlsProps) => {
  return (
    <Wrapper>
      <BaseContent>
        <TopContainer>
          <Title>Tus URLs</Title>
          <Filters onFiltersChange={onFiltersChange} />
        </TopContainer>
        {linksData.data.length > 0 && (
          <>
            <List
              linksData={linksData.data}
              onRefreshData={onRefreshData}
              setLoading={setLoading}
            />
            {linksData.meta.last_page !== 1 && (
              <Paginator
                meta={linksData.meta}
                onChangeCurrentPage={onChangeCurrentPage}
              />
            )}
          </>
        )}
        {linksData.data.length === 0 && (
          <NoDataText>
            No se han encontrado resultados para esta búsqueda
          </NoDataText>
        )}
      </BaseContent>
    </Wrapper>
  );
};

export default ListUrls;
