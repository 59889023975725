import { useState } from "react";
import styled from "styled-components";
import formatDate from "../../../core/functions/format-date";
import Colors from "../../../shared/colors";
import Fonts from "../../../shared/fonts";
import collapseIcon from "../../../shared/icons/collapse.svg";
import expandIcon from "../../../shared/icons/expand.svg";
import { ActionFunctions, ClickActionFunctions, LinkData } from "../interfaces";
import ActionButtons from "./action-buttons";
import Details from "./details";
import EditForm from "./edit-form";

const Container = styled.div`
  border: 1px solid ${Colors.black};
  border-radius: 20px;
  font-family: ${Fonts.barlow};
  color: ${Colors.darkGray};

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 8px 10px 8px 15px;
`;

const Title = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

const ToggleBtn = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 20px 28px;
`;

const InfoContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;

  &.half {
    width: calc((100% - 10px) / 2);
  }
`;

const InfoLabel = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
`;

const InfoText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
`;

const BtnsContainer = styled.div`
  width: 100%;
  padding: 15px 20px 0;
`;

const ActionsContainer = styled.div`
  border-top: 1px solid ${Colors.lightGray3};
  margin: 0 16px 0 13px;
  padding: 13px 0 28px;
`;

interface CardMobileProps {
  linkData: LinkData;
  actionFunctions: ActionFunctions;
  setLoading: (loading: boolean) => void;
}

const CardMobile = ({
  linkData,
  actionFunctions,
  setLoading
}: CardMobileProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [showing, setShowing] = useState<boolean>(false);

  const onClickShow = () => {
    setShowing(!showing);
  };

  const onClickEdit = () => {
    setEditing(!editing);
  };

  const actions: ClickActionFunctions = {
    onClickShow,
    onClickEdit,
    onClickDelete: actionFunctions.onDelete,
    onClickStats: actionFunctions.onStats
  };

  const onToggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Container>
      <Header>
        <Title>{linkData.title}</Title>
        <ToggleBtn onClick={onToggleExpanded}>
          <img
            alt={isExpanded ? "ver menos" : "ver mas"}
            src={isExpanded ? collapseIcon : expandIcon}
          />
        </ToggleBtn>
      </Header>
      {isExpanded && (
        <>
          <Body>
            <InfoContainer>
              <InfoLabel>URL Original</InfoLabel>
              <InfoText>{linkData.original_link}</InfoText>
            </InfoContainer>
            <InfoContainer className="half">
              <InfoLabel>Short URL</InfoLabel>
              <InfoText>{linkData.code}</InfoText>
            </InfoContainer>
            <InfoContainer className="half">
              <InfoLabel>Fecha</InfoLabel>
              <InfoText>{formatDate(linkData.created_at)}</InfoText>
            </InfoContainer>
            <InfoContainer className="half">
              <InfoLabel>Solicitante</InfoLabel>
              <InfoText>{linkData.petitioner}</InfoText>
            </InfoContainer>
            <InfoContainer className="half">
              <InfoLabel>Clicks</InfoLabel>
              <InfoText>{linkData.statistics_count ?? "0"}</InfoText>
            </InfoContainer>
            <BtnsContainer>
              <ActionButtons
                linkId={linkData.id}
                linkCode={linkData.code}
                actions={actions}
                editState={editing}
              />
            </BtnsContainer>
          </Body>
          {(showing || editing) && (
            <ActionsContainer>
              {showing && <Details data={linkData} />}
              {editing && (
                <EditForm
                  linkData={linkData}
                  onFormSubmit={actionFunctions.onEdit}
                  onCancel={() => setEditing(false)}
                  setLoading={setLoading}
                />
              )}
            </ActionsContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default CardMobile;
