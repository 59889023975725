import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import { useMediaQuery } from "usehooks-ts";
import device from "../../../shared/breakpoints";
import { IconButton } from "../../../shared/buttons";
import {
  BadCredentialsError,
  NotFoundError,
  UnknownError
} from "../../../shared/errors";
import deleteActiveIcon from "../../../shared/icons/delete-active.svg";
import deleteIcon from "../../../shared/icons/delete.svg";
import editActiveIcon from "../../../shared/icons/edit-active.svg";
import editIcon from "../../../shared/icons/edit.svg";
import showActiveIcon from "../../../shared/icons/show-active.svg";
import showIcon from "../../../shared/icons/show.svg";
import statisticsActiveIcon from "../../../shared/icons/statistics-active.svg";
import statisticsIcon from "../../../shared/icons/statistics.svg";
import { ClickActionFunctions } from "../interfaces";

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

interface ActionsButtonsProps {
  linkId: number;
  linkCode: string;
  actions: ClickActionFunctions;
  editState: boolean;
}

const ActionButtons = ({
  linkId,
  linkCode,
  actions,
  editState
}: ActionsButtonsProps) => {
  const navigate = useNavigate();
  const isDeskStyle = useMediaQuery(device.tablet);

  const [hoverStatistics, setHoverStatistics] = useState<boolean>(false);
  const [hoverShow, setHoverShow] = useState<boolean>(false);
  const [hoverEdit, setHoverEdit] = useState<boolean>(false);
  const [hoverDelete, setHoverDelete] = useState<boolean>(false);

  const [activeStatistics, setActiveStatistics] = useState<boolean>(false);
  const [activeShow, setActiveShow] = useState<boolean>(false);
  const [activeEdit, setActiveEdit] = useState<boolean>(editState);
  const [activeDelete, setActiveDelete] = useState<boolean>(false);

  useEffect(() => setActiveEdit(editState), [editState]);

  const onClickShow = () => {
    setActiveShow(!activeShow);
    setActiveEdit(false);
    actions.onClickShow();
  };

  const onClickEdit = () => {
    setActiveEdit(!activeEdit);
    setActiveShow(false);
    actions.onClickEdit();
  };

  const onClickDelete = async () => {
    setActiveDelete(true);
    try {
      await actions.onClickDelete(linkId);
    } catch (error) {
      if (error instanceof BadCredentialsError) {
        Swal.fire(
          "Tu sesión ha expirado",
          "Inicia sesión nuevamente para poder hacer la solicitud",
          "error"
        );

        navigate("/login", { replace: true });
      }

      if (error instanceof NotFoundError) {
        Swal.fire(
          "Esta URL no existe",
          "Recarga la página y vuelve a intentar",
          "error"
        );
      }

      if (
        error instanceof UnknownError ||
        !(
          error instanceof BadCredentialsError ||
          error instanceof NotFoundError ||
          error instanceof UnknownError
        )
      ) {
        Swal.fire("¡Ups! Algo salió mal", "Vuelve a intentar", "error");
      }
    }
    setActiveDelete(false);
  };

  const onClickStats = () => {
    setActiveStatistics(!activeStatistics);
    setActiveShow(false);
    actions.onClickStats(linkCode, linkId);
  };

  return (
    <Container>
      <IconButton onClick={onClickStats}>
        <img
          alt="Estadísticas"
          src={
            hoverStatistics || activeStatistics
              ? statisticsActiveIcon
              : statisticsIcon
          }
          onMouseEnter={() => {
            if (isDeskStyle) {
              setHoverStatistics(true);
            }
          }}
          onMouseLeave={() => {
            setHoverStatistics(false);
          }}
        />
      </IconButton>
      <IconButton onClick={onClickShow}>
        <img
          alt="Ver detalles"
          src={hoverShow || activeShow ? showActiveIcon : showIcon}
          onMouseEnter={() => {
            if (isDeskStyle) {
              setHoverShow(true);
            }
          }}
          onMouseLeave={() => {
            setHoverShow(false);
          }}
        />
      </IconButton>
      <IconButton onClick={onClickEdit}>
        <img
          alt="Editar"
          src={hoverEdit || activeEdit ? editActiveIcon : editIcon}
          onMouseEnter={() => {
            if (isDeskStyle) {
              setHoverEdit(true);
            }
          }}
          onMouseLeave={() => {
            setHoverEdit(false);
          }}
        />
      </IconButton>
      <IconButton onClick={onClickDelete}>
        <img
          alt="Eliminar"
          src={hoverDelete || activeDelete ? deleteActiveIcon : deleteIcon}
          onMouseEnter={() => {
            if (isDeskStyle) {
              setHoverDelete(true);
            }
          }}
          onMouseLeave={() => {
            setHoverDelete(false);
          }}
        />
      </IconButton>
    </Container>
  );
};

export default ActionButtons;
