const environment = (process.env.REACT_APP_ENV as keyof typeof URL_PREFIXES) ?? 'dev';

export const CORS_ANYWHERE_PATH =
  "https://corsanywhereentel-dot-entel-vm-md-run.rj.r.appspot.com/";

const URL_PREFIXES = {
  dev: 'https://uat.en.tel/api',
  test: 'https://uat.en.tel/api',
  production: 'https://w2.entel.cl/api'
};

export const urlPrefix = URL_PREFIXES[environment];

const REDIRECTOR_PREFIXES = {
  dev: 'https://uat.en.tel',
  test: 'https://uat.en.tel',
  production: 'https://w2.entel.cl'
};

export const redirectorPrefix = REDIRECTOR_PREFIXES[environment];
