import useAxios from "axios-hooks";
import { useState } from "react";
import { urlPrefix } from "../../core/environment";
import { useAuth } from "../../shared/auth/auth-provider";
import Loader from "../../shared/loader";
import { FilterApiParams, OnApplyFilters, ShortLinksApi } from "./interfaces";
import ListUrls from "./list-urls";
import ShortenerForm from "./shortener-form";

const API_PARAMS: FilterApiParams = { per_page: 10 };

const Home = () => {
  const auth = useAuth();
  const [filters, setFilters] = useState<FilterApiParams>(API_PARAMS);
  const [currentPage, setCurrentPage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(
    undefined
  );

  const [
    { data: linksData, loading: loadingLinks, error: errorLinks },
    refreshShortLinks
  ] = useAxios<ShortLinksApi>({
    url: `${urlPrefix}/shortLinks${currentPage}`,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${auth.token}`
    },
    params: filters
  });

  const onRefreshData = () => refreshShortLinks();

  const onFiltersChange = (data: OnApplyFilters) => {
    if (data.search) {
      setFilters((previous) => ({ ...previous, search: data.search }));
    }

    if (data.filters) {
      setFilters((previous) => ({ ...previous, ...data.filters }));
    }

    if (!data.search && !data.filters) {
      setFilters(API_PARAMS);
    }

    setCurrentPage("");
  };

  const onChangeCurrentPage = (page: number) => {
    setCurrentPage(`?page=${page}`);
  };

  return (
    <>
      {(loadingLinks || loading) && <Loader message={loadingMessage} />}
      <ShortenerForm
        onRefreshData={onRefreshData}
        setLoading={setLoading}
        setLoadingMessage={setLoadingMessage}
      />
      {linksData && (
        <ListUrls
          linksData={linksData}
          onFiltersChange={onFiltersChange}
          onChangeCurrentPage={onChangeCurrentPage}
          onRefreshData={onRefreshData}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default Home;
