import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";
import axios from "axios";

import { urlPrefix } from "../../core/environment";
import { BadCredentialsError, UnknownError } from "../errors";

export interface AuthContextType {
  token: string | null;
  user: User | null;
  signIn: (user: AuthData) => Promise<void>;
  signOut: () => void;
}

export interface User {
  id: number;
  name: string;
  email: string;
  email_verified_at: string; // ISO string
  two_factor_secret: null;
  two_factor_recovery_codes: null;
  two_factor_confirmed_at: null;
  created_at: string; // ISO string
  updated_at: string; // ISO string
  deleted_at: string | null; // ISO string
}

export interface AuthData {
  email: string;
  password: string;
  token: string;
}

export interface LoginValidationErrors {
  email?: string[];
  password?: string[];
  general?: string;
}

export interface ValidationErrorSignIn {
  errors: LoginValidationErrors;
  message: string;
}

const AuthContext = createContext<AuthContextType>(null!);

export class BadRequestError extends Error {
  constructor(public errors: LoginValidationErrors) {
    super();
  }
}

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("token")
  );
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await axios({
          url: `${urlPrefix}/user`,
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token ?? "no token"}`
          }
        });
        setUser(response.data);
      } catch (error) {
        setToken(null);
        setUser(null);
      }
    };

    validateToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (data: AuthData) => {
    try {
      const response = await axios({
        url: `${urlPrefix}/login`,
        method: "POST",
        headers: {
          Accept: "application/json"
        },
        data: data
      });

      const { access_token: token, user } = response.data;

      setUser(user);
      setToken(token);
      localStorage.setItem("token", token);

      return;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response!.status === 422) {
        const data = error.response!.data as ValidationErrorSignIn;

        throw new BadRequestError(data.errors);
      }

      if (axios.isAxiosError(error) && error.response!.status === 401) {
        const data = error.response!.data as { message: string };

        if (data.message.includes("credenciales no coinciden")) {
          throw new BadCredentialsError();
        }

        throw new UnknownError();
      }

      if (axios.isAxiosError(error)) {
        throw new UnknownError();
      }

      throw error;
    }
  };

  const signOut = () => {
    setUser(null);
    setToken(null);
    localStorage.clear();
  };

  const value = { token, user, signIn, signOut };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
