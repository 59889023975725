// ██╗███╗░░░███╗██████╗░░█████╗░██████╗░████████╗░██████╗
// ██║████╗░████║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
// ██║██╔████╔██║██████╔╝██║░░██║██████╔╝░░░██║░░░╚█████╗░
// ██║██║╚██╔╝██║██╔═══╝░██║░░██║██╔══██╗░░░██║░░░░╚═══██╗
// ██║██║░╚═╝░██║██║░░░░░╚█████╔╝██║░░██║░░░██║░░░██████╔╝
// ╚═╝╚═╝░░░░░╚═╝╚═╝░░░░░░╚════╝░╚═╝░░╚═╝░░░╚═╝░░░╚═════╝░

import { ErrorMessage } from '@hookform/error-message';
import useAxios from 'axios-hooks';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import validator from 'validator';
import { urlPrefix } from '../../../core/environment';
import { useAuth } from '../../../shared/auth/auth-provider';
import device from '../../../shared/breakpoints';
import { PrimaryButton } from '../../../shared/buttons';
import Colors from '../../../shared/colors';
import { BadCredentialsError, UnknownError } from '../../../shared/errors';
import Fonts from '../../../shared/fonts';
import { BoxInput, ErrorMsg, FormGroup, Label } from '../../../shared/form-group';

// ░██████╗████████╗██╗░░░██╗██╗░░░░░███████╗██████╗░
// ██╔════╝╚══██╔══╝╚██╗░██╔╝██║░░░░░██╔════╝██╔══██╗
// ╚█████╗░░░░██║░░░░╚████╔╝░██║░░░░░█████╗░░██║░░██║
// ░╚═══██╗░░░██║░░░░░╚██╔╝░░██║░░░░░██╔══╝░░██║░░██║
// ██████╔╝░░░██║░░░░░░██║░░░███████╗███████╗██████╔╝
// ╚═════╝░░░░╚═╝░░░░░░╚═╝░░░╚══════╝╚══════╝╚═════╝░
// ░█████╗░░█████╗░███╗░░░███╗██████╗░░█████╗░███╗░░██╗███████╗███╗░░██╗████████╗░██████╗
// ██╔══██╗██╔══██╗████╗░████║██╔══██╗██╔══██╗████╗░██║██╔════╝████╗░██║╚══██╔══╝██╔════╝
// ██║░░╚═╝██║░░██║██╔████╔██║██████╔╝██║░░██║██╔██╗██║█████╗░░██╔██╗██║░░░██║░░░╚█████╗░
// ██║░░██╗██║░░██║██║╚██╔╝██║██╔═══╝░██║░░██║██║╚████║██╔══╝░░██║╚████║░░░██║░░░░╚═══██╗
// ╚█████╔╝╚█████╔╝██║░╚═╝░██║██║░░░░░╚█████╔╝██║░╚███║███████╗██║░╚███║░░░██║░░░██████╔╝
// ░╚════╝░░╚════╝░╚═╝░░░░░╚═╝╚═╝░░░░░░╚════╝░╚═╝░░╚══╝╚══════╝╚═╝░░╚══╝░░░╚═╝░░░╚═════╝░

const Form = styled.form`
  display: flex;
  flex-direction: column;

  @media ${device.mobileL} {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const FormGroupColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.tablet} {
    width: calc((100% - 30px) / 2);

    &:not(:last-child) {
      margin-right: 30px;
    }
  }
`;

const FlexFG = styled(FormGroup)`
  @media ${device.tablet} {
    max-width: 350px;
  }
`;

const InfoText = styled.span`
  font-family: ${Fonts.barlow};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.gray2};
  padding-top: 8px;
`;

const SubmitBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

const SubmitBtn = styled(PrimaryButton)`
  @media ${device.tablet} {
    width: 160px;
  }
`;

// ██╗███╗░░██╗████████╗███████╗██████╗░███████╗░█████╗░░█████╗░███████╗░██████╗
// ██║████╗░██║╚══██╔══╝██╔════╝██╔══██╗██╔════╝██╔══██╗██╔══██╗██╔════╝██╔════╝
// ██║██╔██╗██║░░░██║░░░█████╗░░██████╔╝█████╗░░███████║██║░░╚═╝█████╗░░╚█████╗░
// ██║██║╚████║░░░██║░░░██╔══╝░░██╔══██╗██╔══╝░░██╔══██║██║░░██╗██╔══╝░░░╚═══██╗
// ██║██║░╚███║░░░██║░░░███████╗██║░░██║██║░░░░░██║░░██║╚█████╔╝███████╗██████╔╝
// ╚═╝╚═╝░░╚══╝░░░╚═╝░░░╚══════╝╚═╝░░╚═╝╚═╝░░░░░╚═╝░░╚═╝░╚════╝░╚══════╝╚═════╝░

export interface IFormInputs {
  mass_creation_file: any;
}

export interface massCreationValidationErrors {
  mass_creation_file?: string[];
}

export interface ValidationErrorResponse {
  errors: massCreationValidationErrors;
  message: string;
}

interface FormSectionProps {
  onFormSubmit: (formValue: IFormInputs) => void;
  setLoading: (loading: boolean) => void;
  setLoadingMessage: (message: string | undefined) => void;
}

// ███████╗██╗░░░██╗███╗░░██╗░█████╗░████████╗██╗░█████╗░███╗░░██╗░██████╗  ███╗░░██╗██╗
// ██╔════╝██║░░░██║████╗░██║██╔══██╗╚══██╔══╝██║██╔══██╗████╗░██║██╔════╝  ████╗░██║╚█║
// █████╗░░██║░░░██║██╔██╗██║██║░░╚═╝░░░██║░░░██║██║░░██║██╔██╗██║╚█████╗░  ██╔██╗██║░╚╝
// ██╔══╝░░██║░░░██║██║╚████║██║░░██╗░░░██║░░░██║██║░░██║██║╚████║░╚═══██╗  ██║╚████║░░░
// ██║░░░░░╚██████╔╝██║░╚███║╚█████╔╝░░░██║░░░██║╚█████╔╝██║░╚███║██████╔╝  ██║░╚███║░░░
// ╚═╝░░░░░░╚═════╝░╚═╝░░╚══╝░╚════╝░░░░╚═╝░░░╚═╝░╚════╝░╚═╝░░╚══╝╚═════╝░  ╚═╝░░╚══╝░░░
// ██╗███╗░░██╗████████╗███████╗██████╗░░█████╗░░█████╗░████████╗██╗░█████╗░███╗░░██╗░██████╗
// ██║████╗░██║╚══██╔══╝██╔════╝██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██║██╔══██╗████╗░██║██╔════╝
// ██║██╔██╗██║░░░██║░░░█████╗░░██████╔╝███████║██║░░╚═╝░░░██║░░░██║██║░░██║██╔██╗██║╚█████╗░
// ██║██║╚████║░░░██║░░░██╔══╝░░██╔══██╗██╔══██║██║░░██╗░░░██║░░░██║██║░░██║██║╚████║░╚═══██╗
// ██║██║░╚███║░░░██║░░░███████╗██║░░██║██║░░██║╚█████╔╝░░░██║░░░██║╚█████╔╝██║░╚███║██████╔╝
// ╚═╝╚═╝░░╚══╝░░░╚═╝░░░╚══════╝╚═╝░░╚═╝╚═╝░░╚═╝░╚════╝░░░░╚═╝░░░╚═╝░╚════╝░╚═╝░░╚══╝╚═════╝░

export class BadRequestError extends Error {
  constructor(public errors: massCreationValidationErrors) {
    super();
  }
}

const FormSection = ({ onFormSubmit, setLoading, setLoadingMessage }: FormSectionProps) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isSubmitSuccessful }
  } = useForm<IFormInputs>();

  const processSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
    setLoading(true);
    setLoadingMessage('Espera un momento mientras se acortan las URLS...');

    const dataToSend = {
      mass_creation_file: data.mass_creation_file
    };

    try {
      await onFormSubmit(dataToSend);
      setLoading(false);
      setLoadingMessage(undefined);
      Swal.fire({
        title: 'Se ha procesado la solicitud exitosamente!',
        icon: 'success',
        toast: true,
        position: 'top-end',
        timer: 3000,
        timerProgressBar: true
      });
    } catch (error) {
      setLoading(false);
      setLoadingMessage(undefined);
      if (error instanceof BadRequestError) {
        if (error.errors.mass_creation_file) {
          setError('mass_creation_file', {
            type: 'custom',
            message: error.errors.mass_creation_file.join(`<br></br>`)
          });
        }
      }

      if (error instanceof BadCredentialsError) {
        Swal.fire('Tu sesión ha expirado', 'Inicia sesión nuevamente para poder hacer la solicitud', 'error');

        navigate('/login', { replace: true });
      }

      if (
        error instanceof UnknownError ||
        !(error instanceof BadRequestError || error instanceof BadCredentialsError || error instanceof UnknownError)
      ) {
        Swal.fire('¡Ups! Algo salió mal', 'Vuelve a intentar', 'error');
      }
    }
    setLoading(false);
    setLoadingMessage(undefined);
  };

  useEffect(() => {
    setLoading(true);
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset, setLoading]);

  return (
    <Form onSubmit={handleSubmit(processSubmit)}>
      <FormGroupColumn>
        <FlexFG>
          <Label>Carga masiva</Label>
          <BoxInput
            type="file"
            placeholder="Seleccione el archivo con las URLS a importar."
            {...register('mass_creation_file', {
              required: 'Este campo es obligatorio.'
            })}
          />
          <ErrorMessage errors={errors} name="mass_creation_file" render={({ message }) => <ErrorMsg>{message}</ErrorMsg>} />
        </FlexFG>
        <SubmitBtnContainer>
          <SubmitBtn type="submit">Iniciar la carga</SubmitBtn>
        </SubmitBtnContainer>
      </FormGroupColumn>
    </Form>
  );
};

export default FormSection;
