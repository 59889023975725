import styled from "styled-components";
import device from "./breakpoints";

export const BaseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const BaseContent = styled.div`
  max-width: 1140px;
  width: 100%;
  padding: 0 20px;
`;

export const MainContainer = styled.div`
  min-height: calc(100vh - (65px + 60px));

  @media ${device.tablet} {
    min-height: calc(100vh - (65px + 50px));
  }
`;
