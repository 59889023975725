import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Colors from "../colors";
import Fonts from "../fonts";

const Container = styled.div`
  display: flex;
`;

const NavItem = styled(NavLink)`
  font-family: ${Fonts.barlow};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${Colors.black};
  text-decoration: none;
  padding: 10px 20px;

  &.active {
    font-weight: 700;
    border-bottom: solid 1px ${Colors.primary};
    color: ${Colors.primary};
  }
`;

const Menu = () => {
  return (
    <Container>
      <NavItem to="/">Inicio</NavItem>
      <NavItem to="/estadisticas">Estadísticas</NavItem>
      <NavItem to="/massCreation">Carga Masiva</NavItem>
    </Container>
  );
};

export default Menu;
