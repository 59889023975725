const formatDate = (ISODate: string) => {
  const date = new Date(ISODate);

  return date
    ? `${date.toLocaleDateString("es-cl")} ${date
        .toLocaleTimeString("es-cl")
        .split(":")
        .slice(0, -1)
        .join(":")}`
    : "";
};

export default formatDate;
