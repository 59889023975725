import styled from "styled-components";
import { redirectorPrefix } from "../../../core/environment";
import copyText from "../../../core/functions/copy-text";
import Colors from "../../../shared/colors";
import Fonts from "../../../shared/fonts";
import { LinkData } from "../interfaces";
import copyIcon from "./img/copy-icon.svg";

const DetailColumn = styled.div``;

const DetailItem = styled.div`
  font-family: ${Fonts.barlow};
  text-align: left;
  font-size: 16px;
  line-height: 24px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const DetailTitle = styled.p`
  font-weight: 500;
  color: ${Colors.darkGray2};
  margin-bottom: 8px;
`;

const DetailValue = styled.p`
  border: 1px solid ${Colors.gray2};
  border-radius: 4px;
  padding: 8px 16px 8px 8px;
  font-weight: 400;
  color: ${Colors.darkGray};
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
`;

const DetailValueCopy = styled(DetailValue)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Colors.gray3};
`;

const ValueText = styled.span`
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const CopyBtn = styled.button`
  border: none;
  background-color: transparent;
  background-image: url(${copyIcon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-left: 32px;
  cursor: pointer;
`;

const Details = ({ data }: { data: LinkData }) => {
  const shortUrl = `${redirectorPrefix}/${data.code}`;

  return (
    <>
      <DetailColumn>
        <DetailItem>
          <DetailTitle>VP</DetailTitle>
          <DetailValue>{data.area.management.vice_presidency.name}</DetailValue>
        </DetailItem>
        <DetailItem>
          <DetailTitle>Gerencia</DetailTitle>
          <DetailValue>{data.area.management.name}</DetailValue>
        </DetailItem>
        <DetailItem>
          <DetailTitle>Área</DetailTitle>
          <DetailValue>{data.area.name}</DetailValue>
        </DetailItem>
      </DetailColumn>
      <DetailColumn>
        <DetailItem>
          <DetailTitle>Solicitante</DetailTitle>
          <DetailValue>{data.petitioner}</DetailValue>
        </DetailItem>
        <DetailItem>
          <DetailTitle>Email solicitante</DetailTitle>
          <DetailValue>{data.email_petitioner}</DetailValue>
        </DetailItem>
        <DetailItem>
          <DetailTitle>Campaña</DetailTitle>
          <DetailValue>{data.campaign}</DetailValue>
        </DetailItem>
      </DetailColumn>
      <DetailColumn>
        <DetailItem>
          <DetailTitle>URL larga</DetailTitle>
          <DetailValueCopy>
            <ValueText>{data.original_link}</ValueText>
            <CopyBtn onClick={() => copyText(data.original_link)}></CopyBtn>
          </DetailValueCopy>
        </DetailItem>
        <DetailItem>
          <DetailTitle>URL corta</DetailTitle>
          <DetailValueCopy>
            <ValueText>{shortUrl}</ValueText>
            <CopyBtn onClick={() => copyText(shortUrl)} />
          </DetailValueCopy>
        </DetailItem>
      </DetailColumn>
    </>
  );
};

export default Details;
