import produce from "immer";
import { range } from "lodash";
import Chart, { ChartWrapperOptions } from "react-google-charts";
import Colors from "../../shared/colors";
import Fonts from "../../shared/fonts";
import { ClicksPerDay, ClicksPerMonth } from "./interfaces";
import { GraphContainer, GraphGroup, GraphTitle } from "./graph-group";

const roundUpNearestTen = (num: number) => {
  return Math.ceil(num / 10) * 10;
};

const roundUpNearestHundred = (num: number) => {
  return Math.ceil(num / 100) * 100;
};

interface TimeGraphProps {
  data: {
    lastTwelveMonths: ClicksPerMonth[];
    lastThirtyDays: ClicksPerDay[];
  };
}

const TimeGraphs = ({ data }: TimeGraphProps) => {
  const lastMonthMatrix = data.lastThirtyDays.map((elem) => {
    const dateArray = elem.day.split("-");
    const date = `${dateArray[2]}-${dateArray[1]}-${dateArray[0].slice(2)}`;

    return [date, elem.clicks];
  });
  lastMonthMatrix.unshift(["Fecha", "Clicks"]);

  const lastYearMatrix = data.lastTwelveMonths.map((elem) => {
    const dateArray = elem.month.split("-");
    const dateObj = new Date(Number(dateArray[0]), Number(dateArray[1]) - 1);
    const month = dateObj.toLocaleString("es-cl", { month: "long" });

    const monthCapitalize = `${month.charAt(0).toUpperCase()}${month.slice(1)}`;

    return [monthCapitalize, elem.clicks];
  });
  lastYearMatrix.unshift(["Mes", "Clicks"]);

  const maxOfClicksLM = Math.max(
    ...data.lastThirtyDays.map((elem) => elem.clicks)
  );

  const maxOfRangeLM = Math.max(roundUpNearestTen(maxOfClicksLM), 10);

  const ticksLastMonth = range(
    0,
    maxOfRangeLM + 1,
    maxOfClicksLM > 10 ? 10 : 1
  );

  const maxOfClicksLY = Math.max(
    ...data.lastTwelveMonths.map((elem) => elem.clicks)
  );

  const maxOfRangeLY = Math.max(
    maxOfClicksLY > 100
      ? roundUpNearestHundred(maxOfClicksLY)
      : roundUpNearestTen(maxOfClicksLY),
    maxOfClicksLY > 100 ? 100 : 10
  );

  const ticksLastYear = range(
    0,
    maxOfRangeLY + 1,
    maxOfRangeLY > 100 ? 100 : maxOfRangeLY > 10 ? 10 : 1
  );

  const defaultOptions: ChartWrapperOptions["options"] = {
    animation: {
      startup: true,
      duration: 300,
      easing: "out",
    },
    chartArea: { width: "80%", height: "75%", top: 10 },
    colors: [Colors.primary],
    fontName: Fonts.barlow,
    legend: "none",
    tooltip: { textStyle: { color: Colors.darkGray } },
    hAxis: {
      baselineColor: Colors.darkGray2,
      maxAlternation: 1,
    },
    vAxis: {
      baselineColor: Colors.darkGray2,
      format: "short",
      gridlines: {
        color: Colors.lightGray2,
        interval: 5.5,
      },
      textStyle: {
        color: Colors.darkGray,
      },
      titleTextStyle: {
        color: Colors.darkGray,
        fontSize: 16,
        italic: false,
      },
    },
  };

  const lastMonthOptions = produce(defaultOptions, (base) => {
    base.vAxis!.ticks = ticksLastMonth;
    base.vAxis!.viewWindow = { min: 0, max: maxOfRangeLM };
  });

  const lastYearOptions = produce(defaultOptions, (base) => {
    base.vAxis!.ticks = ticksLastYear;
    base.vAxis!.viewWindow = { min: 0, max: maxOfRangeLY };
  });

  return (
    <GraphGroup>
      <GraphContainer>
        <Chart
          chartType="LineChart"
          data={lastYearMatrix}
          options={lastYearOptions}
          legendToggle={false}
          width="100%"
          height="300px"
        />
        <GraphTitle>Últimos 12 meses (mensual)</GraphTitle>
      </GraphContainer>
      <GraphContainer>
        <Chart
          chartType="LineChart"
          data={lastMonthMatrix}
          options={lastMonthOptions}
          legendToggle={false}
          width="100%"
          height="300px"
        />
        <GraphTitle>Últimos 30 días (diario)</GraphTitle>
      </GraphContainer>
    </GraphGroup>
  );
};

export default TimeGraphs;
