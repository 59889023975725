import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import device from "../../../shared/breakpoints";
import { IconButton, OutlineButton } from "../../../shared/buttons";
import Colors from "../../../shared/colors";
import Fonts from "../../../shared/fonts";
import { OnApplyFilters } from "../interfaces";
import FiltersModal from "./filters-modal";

import filtersIcon from "./img/filter-icon.svg";
import searchIcon from "./img/search-icon.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.tablet} {
    flex-direction: row;
    width: auto;
  }
`;

const FilterIcon = styled.img`
  width: 18px;
  margin-right: 8px;
`;

const Form = styled.form`
  display: flex;
  margin-top: 15px;

  @media ${device.tablet} {
    margin-left: 15px;
    margin-top: 0;
  }
`;

const SearchBtn = styled(IconButton)`
  height: 45px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.black};
  border-left: none;
  border-radius: 0 10px 10px 0;
  padding: 10px 12px;
`;

const SearchInput = styled.input`
  border: 1px solid ${Colors.black};
  border-right: none;
  border-radius: 10px 0 0 10px;
  font-family: ${Fonts.barlow};
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  height: 45px;
  padding: 12px 12px 12px 30px;
  width: calc(100% - 48px);

  ::-webkit-input-placeholder {
    color: ${Colors.gray2};
  }

  &:active,
  &:focus,
  &:focus-visible {
    border-color: ${Colors.primary};
    box-shadow: none;
    outline: none;
  }

  &:active
    + ${SearchBtn},
    &:focus
    + ${SearchBtn},
    &:focus-visible
    + ${SearchBtn} {
    border-color: ${Colors.primary};
    box-shadow: none;
    outline: none;
  }

  @media ${device.tablet} {
    width: 302px;
    margin-left: 15px;
    margin-top: 0;
  }
`;

interface SearchFormInput {
  search: string;
}

const Filters = ({
  onFiltersChange,
}: {
  onFiltersChange: (filtersData: OnApplyFilters) => void;
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (openModal) {
      document.querySelector("body")!.style.overflowY = "hidden";
    }

    if (!openModal) {
      document.querySelector("body")!.style.overflowY = "auto";
    }
  }, [openModal]);

  const { register, handleSubmit } = useForm<SearchFormInput>();

  const onSubmit: SubmitHandler<SearchFormInput> = async (
    data: SearchFormInput
  ) => {
    if (data.search) {
      onFiltersChange({ search: data.search });
    } else {
      onFiltersChange({});
    }
  };

  const onOpenFilters = () => setOpenModal(true);
  const onCloseFilters = () => setOpenModal(false);

  return (
    <Wrapper>
      <OutlineButton onClick={onOpenFilters}>
        <FilterIcon src={filtersIcon} />
        Filtrar
      </OutlineButton>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SearchInput
          type="text"
          {...register("search", {
            maxLength: {
              value: 30,
              message: "Se permite máximo 30 caracteres.",
            },
          })}
          placeholder="Escribe tu búsqueda"
        />
        <SearchBtn type="submit">
          <img src={searchIcon} alt="buscar" />
        </SearchBtn>
      </Form>
      <FiltersModal
        isOpen={openModal}
        onCloseModal={onCloseFilters}
        onApplyFilters={onFiltersChange}
      />
    </Wrapper>
  );
};

export default Filters;
