import { useState } from "react";
import { useAuth } from "../../shared/auth/auth-provider";
import Loader from "../../shared/loader";
import MassCreationForm from "./mass-creation-form";

const MassCreation = () => {
  const auth = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(
    undefined
  );

  return (
    <>
      {/* {loading && <Loader message={loadingMessage} />} */}
      <MassCreationForm
        setLoading={setLoading}
        setLoadingMessage={setLoadingMessage}
      />
    </>
  );
};

export default MassCreation;
