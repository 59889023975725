import { Navigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import Loader from "../loader";
import Navbar from "../navbar";
import { useAuth } from "./auth-provider";

const Container = styled.div`
  width: 100%;
`;

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.token && !auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (auth.token && !auth.user) {
    return <Loader />;
  }

  return (
    <Container>
      <Navbar />
      {children}
    </Container>
  );
};

export default RequireAuth;
