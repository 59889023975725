import styled from "styled-components";
import device from "../breakpoints";
import Colors from "../colors";
import Fonts from "../fonts";
import { useAuth } from "./auth-provider";

const Container = styled.div`
  display: flex;
  align-items: flex-end;

  @media ${device.tablet} {
    padding-right: 20px;
  }
`;

const UserText = styled.p`
  font-family: ${Fonts.barlow};
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  color: ${Colors.black};

  b {
    font-weight: 600;
    display: block;
  }

  @media ${device.tablet} {
    font-size: 30px;
    line-height: 36px;

    b {
      font-weight: 600;
      display: inline-block;
    }
  }
`;

const LogoutBtn = styled.button`
  font-family: ${Fonts.barlow};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.primary};
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin-left: 12px;
  white-space: nowrap;
`;

const AuthStatus = () => {
  const auth = useAuth();

  return (
    <Container>
      {auth.user ? (
        <>
          <UserText>
            Hola!, <b>{auth.user.name}</b>
          </UserText>
          <LogoutBtn onClick={auth.signOut}>(Log out)</LogoutBtn>
        </>
      ) : (
        <UserText>No has iniciado sesión aún</UserText>
      )}
    </Container>
  );
};

export default AuthStatus;
